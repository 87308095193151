import {SrsModel} from './srs.model';

export class ProvinceModel {

    id: number;
    name: string;
    srs: number;

    public constructor(private object: any) {
        this.id = object.id;
        this.name = object.name;
        this.srs = object.srs;
    }

    get idAsString() {
        return this.id.toString();
    }

}
