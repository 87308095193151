import {AppConfig} from '../../app.config';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Injectable} from '@angular/core';

const jwt = new JwtHelperService();

@Injectable()
export class LoginService {
    config: any;
    _isFetching: boolean = false;
    _errorMessage: string = '';

    constructor(
        appConfig: AppConfig,
        private http: HttpClient,
        private router: Router,
    ) {
        this.config = appConfig.getConfig();
    }

    get isFetching() {
        return this._isFetching;
    }

    set isFetching(val: boolean) {
        this._isFetching = val;
    }

    get errorMessage() {
        return this._errorMessage;
    }

    set errorMessage(val: string) {
        this._errorMessage = val;
    }

    isAuthenticated() {
        // TODO implementar
        // const token = localStorage.getItem('token');
        //
        // let data = null;
        //
        // // We check if app runs with backend mode
        // if (!this.config.isBackend && token) return true;
        // if (!token) return;
        // const date = new Date().getTime() / 1000;
        // try {
        // data = jwt.decodeToken(token);
        // } catch(e) {
        //   this.router.navigate(['/login']);
        // }
        // if (!data) return;
        // return date < data.exp;
        return false;
    }

    loginUser(creds) {
        // We check if app runs with backend mode
        if (!this.config.isBackend) {
            this.receiveToken('token');
        } else {
            this.requestLogin();
            if (creds.social) {
                // tslint:disable-next-line
                window.location.href = this.config.baseURLApi + '/user/signin/' + creds.social + (process.env.NODE_ENV === 'production' ? '?app=sing-app/angular' : '');
            } else if (creds.email.length > 0 && creds.password.length > 0) {
                this.http.post('/user/signin/local', creds).subscribe((res: any) => {
                    const token = res.token;
                    this.receiveToken(token);
                }, err => {
                    this.loginError('Something was wrong. Try again');
                });

            } else {
                this.loginError('Something was wrong. Try again');
            }
        }
    }

    receiveToken(token) {
        let user: any = {};
        // We check if app runs with backend mode
        if (this.config.isBackend) {
            user = jwt.decodeToken(token).user;
            delete user.id;
        } else {
            user = {
                email: this.config.auth.email
            };
        }

        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        this.receiveLogin();
    }

    logoutUser() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        this.router.navigate(['/login']);
    }

    loginError(payload) {
        this.isFetching = false;
        this.errorMessage = payload;
    }

    receiveLogin() {
        this.isFetching = false;
        this.errorMessage = '';
        this.router.navigate(['/app/analytics']);
    }

    requestLogin() {
        this.isFetching = true;
    }
}
