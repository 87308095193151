import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {CountyModel} from '../model/county.model';
import {ProvinceModel} from '../model/province.model';
import {Observable} from 'rxjs/Observable';
import {UnapModel} from '../model/unap.model';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {

    constructor(private db: AngularFirestore) {
        this.loadData();
    }

    static centerPrimerNivel = 'Centro de Primer Nivel';
    static supervisores = 'Supervisores';
    static inaipi = 'INAIPI';
    static ong = 'ONG';
    static pastoral = 'Pastoral Materno Infantil';
    static fundations = 'Fundaciones y sector Privado';
    static cruzRoja = 'Cruz Roja';
    static hospital = 'Hospital';
    static others = 'Others';
    static dps = 'DPS';
    static srs = 'SRS - Servicio Regional de Salud';
    static sns = 'SNS - Servicio Nacional de Salud';
    static ministerioSalud = 'Ministerio de Salud';
    static unicef = 'UNICEF';
    static nationalOrganizations = [
        'SNS - Servicio Nacional de Salud',
        'Ministerio de Salud', 'UNICEF',
        OrganizationService.cruzRoja,
        OrganizationService.inaipi,
        OrganizationService.pastoral,
        OrganizationService.hospital
    ];

    private data = new Array<UnapModel>();

    organizationInitials = [
        'Centro de Primer Nivel',
        'Cruz Roja',
        'Fundaciones y sector Privado',
        'Hospital',
        'INAIPI',
        'ONG',
        'Supervisores',
        'Others'
    ];
    unaps = ['UNAP Rincón Hondo', 'UNAP Las Guazumas', 'UNAP El Limonal', 'UNAP 30 de mayo', 'UNAP El Pinito Sabaneta'];
    organizationTypes = [
        'DPS',
        'SRS - Servicio Regional de Salud',
        'SNS - Servicio Nacional de Salud',
        'Ministerio de Salud', 'UNICEF',
        'Cruz Roja',
        'INAIPI',
        'Pastoral Materno Infantil',
        OrganizationService.hospital
    ];

    static isONG(organizationInitials: string) {
        return organizationInitials === 'ONG';
    }

    permitFreeField(organizationInitials: string) {
        return ['Fundaciones y sector Privado', 'Others'].includes(organizationInitials);
    }

    isNational(organization: string) {
        return OrganizationService.nationalOrganizations.includes(organization);
    }

    isSupervisor(organizationInitials: string) {
        return organizationInitials == OrganizationService.supervisores;
    }

    isValidOrganizationType(value) {
        return this.organizationInitials.includes(value);
    }

    onlyIdentify(value) {
        return value !== OrganizationService.centerPrimerNivel &&
            value !== OrganizationService.supervisores;
    }

    identifyAndTreat(value) {
        return value !== OrganizationService.supervisores;
    }

    showUnapSelect(value) {
        return value === OrganizationService.centerPrimerNivel;
    }

    showOrganizationSelect(value) {
        return value === OrganizationService.supervisores;
    }

    listByCounty(county: CountyModel): Observable<UnapModel[]> {
        return this.db.collection<UnapModel>('unap', ref => ref.where('countyReference', '==', county?.name)).valueChanges();
    }

    listOng(province: ProvinceModel) {
        return this.db.collection('ong', ref => ref.where('provinceReference', '==', province?.name.trim())).valueChanges();
    }

    listDps(): Observable<UnapModel[]> {
        return this.db.collection<UnapModel>('dps', ref => ref.orderBy('name')).valueChanges();
    }

    listSrs(): Observable<UnapModel[]> {
        return this.db.collection<UnapModel>('srs').valueChanges();
    }

    get listAll() {
        return this.data;
    }

    findByCounty(county: CountyModel) {

        if (!county || !county.id) {
            return;
        }
        return this.data
            .filter(unap => unap.county === county.id)
            .sort(this.compare);

    }

    private compare(unap1, unap2) {

        let comparison = 0;

        if (unap1.name > unap2.name) {
            comparison = 1;
        } else if (unap1.name < unap2.name) {
            comparison = -1;
        }

        return comparison;

    }

    public loadData() {
        this.db.firestore.collection('unap')
            .onSnapshot({includeMetadataChanges: true}, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    this.data.push(change.doc.data() as UnapModel);
                });
            });
    }

}
