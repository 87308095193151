import firebase from 'firebase';
import {VisitModel} from './visit.model';
import {User} from './user.model';
import {EatingHabitModel} from './eating-habit.model';
import {ProvinceModel} from './province.model';
import {CountyModel} from './county.model';
import {TokenGeneratorUtil} from '../util/token-generator.util';
import {TreatmentCase} from './treatment-case';
import GeoPoint = firebase.firestore.GeoPoint;
import {UnapModel} from './unap.model';
import {DateUtil} from '../util/date.util';

export class ChildCaseModel implements TreatmentCase {

    public objectID: string;
    public token: string;
    public muac: number;
    public marker: string;
    public anotherDisease: string;
    public chronicDisease: string;
    public childToken: string;
    public childName: string;
    public firstName: string;
    public lastName: string;
    public gender: string;
    public birthCertificateNumber: string;
    public mothersName: string;
    public mothersPhone: string;
    public caregiverName: string;
    public caregiverPhone: string;
    public childBirthDate: Date;
    public childBirthDateTimestamp: number;
    public childSelfie: string;
    public childSelfieRef: string;
    public childReferredToTheHospital: boolean;
    public assistantUid: string;
    public organizationName: string;
    public organizationInitials: string;
    public organization: UnapModel;
    public province: string;
    public provinceModel: ProvinceModel;
    public county: string;
    public countyModel: CountyModel;
    public date: Date;
    public dateTimestamp: number;
    public updateDate: Date;
    public nextVisit: string;
    public nextVisitDate: Date;
    public nextVisitTimestamp: number;
    public yearOfVisit: number;
    public visits = new Array<VisitModel>();
    public identifierAssistant: User;
    public transferAssistant: User;
    public transferredCase = false;
    public healthAssistants = new Array<User>();
    public currentAssistantUid: string;
    public healthAssistantsUids = new Array<string>();
    public symptoms: Array<string>;
    public comments: string;
    public alert: any;
    public icon: any;
    public eatingHabits = new Array<EatingHabitModel>();
    public identifiedCase = false;
    public location: GeoPoint;
    public childAddress: string;
    public appVersion: string;

    constructor(private assistant?: User) {
        this.healthAssistants.push(assistant);
        this.assistantUid = assistant.uid;
        this.healthAssistantsUids.push(assistant.uid);
        this.organizationInitials = assistant.organizationInitials;
        this.organizationName = assistant.organizationName;
        this.childToken = TokenGeneratorUtil.generateToken();
        this.token = TokenGeneratorUtil.generateToken();
    }

    public addEatingHabits(eatingHabits: EatingHabitModel) {
        if (eatingHabits) {
            let order: number = this.visits.filter(visit => visit.date).length || 0;
            eatingHabits.order = order++;
            this.eatingHabits.push(eatingHabits);
        }
    }

}
