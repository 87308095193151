import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalModel} from '../../model/modal.model';
import {ButtonActionModel} from '../../model/button-action.model';

@Component({
    selector: 'picture-changing',
    templateUrl: './picture-changing.component.html',
    styleUrls: ['./picture-changing.component.scss']
})
export class PictureChangingComponent implements OnInit {

    @Input()
    public selfie: string;
    @Input()
    public modalConfig: ModalModel;
    @Input()
    public title: string;
    @Input()
    public message: string;
    @Input()
    public image: string;
    @Output()
    pictureOutput = new EventEmitter<string>();
    public cancel: any;
    @Input()
    public actionButton: ButtonActionModel;
    @Input()
    public template: any;

    @Input()
    public disabled: boolean = false;
  
    public noProfilePicture = '/assets/img/icons/no_profile_picture.svg';

    constructor() {
    }

    changePicture(picture) {
        if (picture) {
            this.selfie = picture as string;
            this.pictureOutput.emit(this.selfie);
        }
    }

    open(content) {
        content.open(content.templateContent, {
            ariaLabelledBy: 'app-modal-component',
            centered: true,
        }).result.then((closeAction) => {
            if (closeAction) {
                closeAction();
            }
        });
    }

    ngOnInit(): void {
        console.log('Selfie: ', this.selfie);
    }

    getSelfie(){
        if(this.selfie === undefined || this.selfie === null || this.selfie === '' || this.selfie.indexOf('no_profile_picture') !== -1){
            return this.noProfilePicture;
        }else{
            return this.selfie;
        }
    }

}
