import {Component, Input, OnInit} from '@angular/core';
import {ModalOptions} from '../../options/modal.options';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from '../../services/loading.service';

@Component({
    selector: 'app-modal-error',
    templateUrl: './modal-error.component.html',
    styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorComponent implements OnInit {

    @Input()
    public options: ModalOptions;
    @Input()
    public translate: boolean = false;

    constructor(public activeModal: NgbActiveModal, private loadingService: LoadingService) {
    }

    ngOnInit(): void {
        this.loadingService.stop();
    }
}
