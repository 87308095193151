export class NumberUtil {

  static parseFloat(value: any) {
    if (!value) {
      return;
    }
    return parseFloat(value);
  }

  static toFixed(value: any, fixedValue: number) {
    if (!value) {
      return;
    }
    return parseFloat(value).toFixed(fixedValue);
  }
}
