import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/storage';
import {ModalModel} from '../model/modal.model';
import {TranslocoService} from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class PictureService {

    static NO_PROFILE_PICTURE = 'no_profile_picture.svg';

    public static getPhotoUrl(photo: string): string {
        if(!photo || (photo.indexOf(this.NO_PROFILE_PICTURE) >= 0)) {
            return '/assets/img/icons/no_profile_picture.svg';
        }else{
        return photo;
        }
    }

    constructor(private storage: AngularFireStorage,
                private translocoService: TranslocoService) {
    }

    public async getPictureURL(filePath: string) {
        const ref = this.storage.ref(filePath);
        return new Promise<string>(resolve => ref.getDownloadURL().subscribe(url => resolve(url), error => console.error(error)));
    }

    public upload(file: Blob): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            const path = this.generateUUID();
            await this.storage.upload(path, file);
            resolve(path);
        });
    }

    private generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (str) {
            const newStr = Math.random() * 16 | 0, number = str == 'x' ? newStr : (newStr & 0x3 | 0x8);
            return number.toString(16);
        });
    }


    public changePictureConfig(type: string): ModalModel {
        return {
            modal: {
                title: this.translocoService.translate(`${type}.takePicture.initialAlert.title`),
                altTitle: this.translocoService.translate(`${type}.takePicture.initialAlert.altTitle`),
                message: this.translocoService.translate(`${type}.takePicture.initialAlert.message`),
                altMessage: this.translocoService.translate(`${type}.takePicture.initialAlert.altMessage`),
                img: this.translocoService.translate(`${type}.takePicture.initialAlert.img`),
                primaryButton: {
                    text: 'Foto con webcam',
                    action: () => console.log('ihuuu'),
                },
                secondaryButton: {
                    text: 'Elija el archivo',
                    action: () => console.log('ihuuu'),
                }
            },
            btn: {
                text: '',
                styles: 'btn new no-margin'
            }
        };
    }

}
