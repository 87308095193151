export class HospitalModel {

    public id: number;
    public name: string;
    public countyId: number;
    public provinceId: number;

    constructor(id: number, name: string, countyId: number) {
        this.id = id;
        this.name = name;
        this.countyId = countyId;
    }

}
