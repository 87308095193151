import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {SrsModel} from '../model/srs.model';

@Injectable({
    providedIn: 'root'
})
export class SrsService {

    private data = new Array<SrsModel>();

    constructor(private db: AngularFirestore) {
        this.loadData();
    }

    listAll() {
        return this.data.sort(this.compare);
    }

    getByName(name: string) {
        console.log('find for ', name);
        if (!name) {
            return;
        }
        return this.data
            .filter(object => object.name == name)[0];
    }

    async searchByName(name: string) : Promise<SrsModel> {
        return await this.db.firestore.collection('srs').where('name', '==', name).get().then(querySnapshot => {
            if(querySnapshot.docs.length > 0) {
                return querySnapshot.docs[0].data() as SrsModel;
            }else{
                return null;
            }
        });
    }

    getByCode(code: number) {
        if (!code) {
            return;
        }
        return this.data
            .filter(object => object.id == code)[0];
    }

    private compare(province1, province2) {

        let comparison = 0;

        if (province1.name > province2.name) {
            comparison = 1;
        } else if (province1.name < province2.name) {
            comparison = -1;
        }

        return comparison;

    }

    public loadData() {
        this.db.firestore.collection('srs')
            .onSnapshot({includeMetadataChanges: true}, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    this.data.push(change.doc.data() as SrsModel);
                });
            });
    }

}
