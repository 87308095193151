import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {User} from '../model/user.model';
import {ParseObjectUtil} from '../util/parse-object.util';
import {PictureService} from './picture.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(public db: AngularFirestore,
                public pictureService: PictureService) {
    }

    public getUserByUID(uid: string) {
        return this.db.collection<User>('users').doc(uid);
    }

    async createUser(user: User) {
        if (user.isOrgSupervisors) {
            user.countyModel = null;
            user.county = null;
        }
        if (user.selfie && user.selfie.includes('data')) {
            await fetch(user.selfie).then(async response => {
                user.selfieRef = await this.pictureService.upload(await response.blob());
                user.selfie = await this.pictureService.getPictureURL(user.selfieRef) as string;
                return this.db.collection<User>('users').doc(user.uid)
                    .set(ParseObjectUtil.parse(user)).then(() => console.log('successfully'));
            });
        }

        return this.db.collection<User>('users').doc(user.uid).set(ParseObjectUtil.parse(user)).then(() => console.log('successfully'));
    }

    async update(user: User) {
        console.log('update user', user);
        if (user.selfie && user.selfie.includes('data')) {
            await fetch(user.selfie).then(async response => {
                user.selfieRef = await this.pictureService.upload(await response.blob());
                user.selfie = await this.pictureService.getPictureURL(user.selfieRef) as string;
                return this.db.collection<User>('users').doc(user.uid)
                    .set(ParseObjectUtil.parse(user)).then(() => console.log('successfully'));
            });
        }
        return this.db.collection<User>('users').doc(user.uid).set(ParseObjectUtil.parse(user), {merge: true});
    }

}
