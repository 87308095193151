export class MalnutritionLevelUtil {

    public static HEALED = {
        level: 0,
        description: 'healed'
    };
    public static OUTSIDE_RISK_ZONE = {
        level: 1,
        description: 'outsite-risk-zone'
    };
    public static RISK_OF_ACUTE_MALNUTRITION = {
        level: 2,
        description: 'risk-of-acute-malnutrition'
    };
    public static MODERATE_ACUTE_MALNUTRITION = {
        level: 3,
        description: 'moderate-acute-malnutrition'
    };
    public static SEVERE_ACUTE_MALNUTRITION = {
        level: 4,
        description: 'severe-acute-malnutrition'
    };


    public static manutritionLevels() {
        return [
            MalnutritionLevelUtil.SEVERE_ACUTE_MALNUTRITION,
            MalnutritionLevelUtil.MODERATE_ACUTE_MALNUTRITION,
            MalnutritionLevelUtil.RISK_OF_ACUTE_MALNUTRITION
        ];
    }

    public static values() {
        return [MalnutritionLevelUtil.HEALED,
            MalnutritionLevelUtil.OUTSIDE_RISK_ZONE,
            MalnutritionLevelUtil.RISK_OF_ACUTE_MALNUTRITION,
            MalnutritionLevelUtil.MODERATE_ACUTE_MALNUTRITION,
            MalnutritionLevelUtil.SEVERE_ACUTE_MALNUTRITION].map(obj => Object.assign({}, obj));
    }

}
