import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {CountyModel} from '../model/county.model';
import {Observable} from 'rxjs/Observable';
import {InaipiModel} from '../model/inaipi.model';

@Injectable({
    providedIn: 'root'
})
export class HospitalService {

    private collectionName: string = 'hospital';

    constructor(private db: AngularFirestore) {
    }

    listByCounty(county: CountyModel): Observable<InaipiModel[]> {
        return this.db.collection<InaipiModel>(this.collectionName, ref => ref.where('countyId', '==', county?.id)).valueChanges();
    }

}
