export class Dashboard {

    total: number = 0;
    noProgress: number = 0;
    transferred: number = 0;
    moderateAcuteMalnutrition: number = 0;
    interruptedTreatment: number = 0;
    outsideRiskZone: number = 0;
    riskOfAcuteMalnutrition: number = 0;
    severeAcuteMalnutrition: number = 0;
    noMeasured: number = 0;
    hospitalized: number = 0;
    healed: number = 0;

    public add(dashboard) {
        this.total++;
        this.noProgress = this.noProgress + dashboard.noProgress;
        this.transferred = this.transferred + dashboard.transferred;
        this.severeAcuteMalnutrition = this.severeAcuteMalnutrition + dashboard.severeAcuteMalnutrition;
        this.moderateAcuteMalnutrition = this.moderateAcuteMalnutrition + dashboard.moderateAcuteMalnutrition;
        this.interruptedTreatment = this.interruptedTreatment + dashboard.interruptedTreatment;
        this.outsideRiskZone = this.outsideRiskZone + dashboard.outsideRiskZone;
        this.riskOfAcuteMalnutrition = this.riskOfAcuteMalnutrition + dashboard.riskOfAcuteMalnutrition;
        this.noMeasured = this.noMeasured + dashboard.noMeasured;
        this.hospitalized = this.hospitalized + dashboard.hospitalized;
        this.healed = this.healed + dashboard.healed;
    }

    public clear() {
        this.total = 0;
        this.noProgress = 0;
        this.transferred = 0;
        this.moderateAcuteMalnutrition = 0;
        this.interruptedTreatment = 0;
        this.outsideRiskZone = 0;
        this.riskOfAcuteMalnutrition = 0;
        this.severeAcuteMalnutrition = 0;
        this.noMeasured = 0;
        this.hospitalized = 0;
        this.healed = 0;
    }

    public updateLevel(marker) {
        switch (marker) {
            case 'no-measured':
                this.noMeasured++;
                break;
            case 'healed':
                this.healed++;
                break;
            case 'outsite-risk-zone':
            case 'outside-risk-zone':
                this.outsideRiskZone++;
                break;
            case 'severe-acute-malnutrition':
                this.severeAcuteMalnutrition++;
                break;
            case 'risk-of-acute-malnutrition':
                this.riskOfAcuteMalnutrition++;
                break;
            case 'moderate-acute-malnutrition':
                this.moderateAcuteMalnutrition++;
                break;
        }
    }

    public downgradeLevel(marker) {
        switch (marker) {
            case 'no-measured':
                this.noMeasured--;
                break;
            case 'healed':
                this.healed--;
                break;
            case 'outsite-risk-zone':
            case 'outside-risk-zone':
                this.outsideRiskZone--;
                break;
            case 'severe-acute-malnutrition':
                this.severeAcuteMalnutrition--;
                break;
            case 'risk-of-acute-malnutrition':
                this.riskOfAcuteMalnutrition--;
                break;
            case 'moderate-acute-malnutrition':
                this.moderateAcuteMalnutrition--;
                break;
        }
    }

}
