import {Component, Input, OnInit} from '@angular/core';
import {ModalOptions} from '../../options/modal.options';
import {LoadingService} from '../../services/loading.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirm-word',
  templateUrl: './modal-confirm-word.component.html',
  styleUrls: ['./modal-confirm-word.component.scss']
})
export class ModalConfirmWordComponent implements OnInit {

  @Input()
  public options: ModalOptions;

  confirmationWord: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  isValid(): boolean{
    return this.confirmationWord && this.confirmationWord.length > 0 && this.confirmationWord === this.options.word;
  }

}
