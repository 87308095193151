import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CameraModalComponent} from './camera-modal.component';
import {CameraModule} from '../camera/camera.module';


@NgModule({
    declarations: [CameraModalComponent],
    imports: [
        CommonModule,
        CameraModule
    ], exports: [CameraModalComponent]
})
export class CameraModalModule {
}
