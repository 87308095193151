import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ParseObjectUtil {

    constructor() {
    }

    static parse(object: any): any {
        return JSON.parse(JSON.stringify(object));
    }

}
