import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AppConfig} from '../../app.config';
import {LoginModel} from '../../model/login.model';
import {LoginService} from '../../services/login.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.template.html'
})
export class LoginComponent implements OnInit {
    @HostBinding('class') classes = 'auth-page app';

    illustrationLogoNutreMuac = 'assets/img/logos/illustration-logo.svg';
    logoNutreMuac = 'assets/img/logos/logo.png';
    iconInfo = 'assets/img/icons/icon-info.svg';
    logoSns = 'assets/img/logos/logo-sns.svg';
    logoMinSalud = 'assets/img/logos/logo-min-salud.png';
    logoUsaid = 'assets/img/logos/logo-usaid.png';
    logoUnicef = 'assets/img/logos/logo-unicef.svg';

    credentials = new LoginModel();

    constructor(
        public loginService: LoginService,
        private route: ActivatedRoute,
        appConfig: AppConfig) {

    }

    public login() {
        console.log('PASSOU');
        this.loginService.login(this.credentials).then(() => {
            console.log('login successfully');
        }).catch(error => {
            console.log('login error', error);
        });
    }

    ngOnInit() {
    }

}
