import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ValidationComponent} from './validation.component';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
    declarations: [ValidationComponent],
    imports: [
        CommonModule,
        TranslocoModule
    ],
    exports: [ValidationComponent]
})
export class ValidationModule {
}
