import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ChildCaseService} from '../../services/child-case.service';
import {Router} from '@angular/router';
import {ChildSimilarCaseResponse} from '../../model/similar-cases/child/child-similar-case-response';
import {ChildSimilarCaseUserDecision} from '../../model/similar-cases/child/child-similar-case-user-decision';
import {ChildSimilarCaseDetected} from '../../model/similar-cases/child/child-similar-case-detected';

@Component({
    selector: 'modal-child-similar-cases',
    templateUrl: './modal-child-similar-cases.component.html',
    styleUrls: ['./modal-child-similar-cases.component.scss']
})
export class ModalChildSimilarCasesComponent implements OnInit {
    @Input() data!: ChildSimilarCaseResponse;
    protected readonly confirm = confirm;

    constructor(public activeModal: NgbActiveModal,
                public childCaseService: ChildCaseService,
                private router: Router) {
    }

    showChildProfile(selectedCase: ChildSimilarCaseDetected) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['app/child-profile/', selectedCase.objectID])
        );
        const baseUrl = window.location.origin.replace(/\/$/, '');
        sessionStorage.setItem('openChildProfileOnPopup', selectedCase.objectID);
        window.open(`${baseUrl}/#${url}`, '_blank');
    }

    markAsNoSimilar() {
        const userDecision = new ChildSimilarCaseUserDecision();
        userDecision.markedAsNoSimilar = true;
        this.activeModal.close(userDecision);
    }

    get modalTitle(): string {
        const caseCount = this.data?.cases?.length || 0;
        if (caseCount === 0) {
            return 'Casos similares';
        }
        if (caseCount === 1) {
            return 'Identificamos 1 caso similar';
        }
        return `Identificamos ${caseCount} casos similares`;
    }

    ngOnInit(): void {
    }

}
