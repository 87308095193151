import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {CountyModel} from '../model/county.model';
import {Observable} from 'rxjs/Observable';
import {CruzRojaModel} from '../model/cruz-roja.model';

@Injectable({
    providedIn: 'root'
})
export class CruzRojaService {

    private collectionName: string = 'cruzRoja';

    constructor(private db: AngularFirestore) {
    }

    listByCounty(county: CountyModel): Observable<CruzRojaModel[]> {
        return this.db.collection<CruzRojaModel>(this.collectionName, ref => ref.where('countyId', '==', county?.id)).valueChanges();
    }

}
