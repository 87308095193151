import {Component, Input, OnInit} from '@angular/core';
import {VisitModel} from '../../model/visit.model';

@Component({
    selector: 'visits',
    templateUrl: './visits.component.html',
    styleUrls: ['./visits.component.scss']
})
export class VisitsComponent implements OnInit {

    constructor() {
    }

    @Input()
    public visits: Array<VisitModel>;

    @Input()
    public page: string;

    @Input()
    public showTitle: boolean = true;

    @Input()
    public border: boolean = false;

    generateVisits(visits: Array<VisitModel>): Array<VisitModel> {
        const numberOfVisits = visits.length;
        const markers = new Array<string>();
        if (visits.length > 0) {
            for (let i = 0; i < visits.length; i++) {
                markers.push(visits[i].marker);
                visits[i].order = i;
                visits[i].stages = [...markers];
            }
        }
        for (let i = 0; i < (5 - numberOfVisits); i++) {
            visits.push(this.emptyVisit());
        }
        return visits;
    }

    emptyVisit(): VisitModel {
        const visit = new VisitModel();
        visit.marker = 'no-measured';
        return visit;
    }

    ngOnInit() {
    }

    getBorderClass(): string {
        if (this.border) {
            return 'visit-icon-border';
        }
        return '';
    }

}
