import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {LoginService} from './services/login.service';

@Injectable()
export class AppGuard implements CanActivate {
    constructor(
        private router: Router,
        private loginService: LoginService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.loginService.isAuthenticated()) {
            return true;
        } else {
            this.router.navigate(['']).then(() => {
                console.log('User not authenticated. Redirecting to login');
            });
        }
    }
}
