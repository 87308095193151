import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {ROUTES} from './app.routes';
import {AppComponent} from './app.component';
import {AppConfig} from './app.config';
import {ErrorComponent} from './pages/error/error.component';
import {AppInterceptor} from './app.interceptor';
import {LoginService} from './pages/login/login.service';
import {AppGuard} from './app.guard';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {VisitsModule} from './components/visits/visits.module';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {MuacModule} from './components/muac/muac.module';
import {TextListModule} from './components/text-list/text-list.module';
import {TranslocoRootModule} from './transloco/transloco-root.module';
import {LoginModule} from './pages/login/login.module';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AssistedByModule} from './components/assisted-by/assisted-by.module';
import {ValidationModule} from './components/validation/validation.module';
import {CameraModule} from './components/camera/camera.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PictureChangingModule} from './components/picture-changing/picture-changing.module';
import {CameraModalModule} from './components/camera-modal/camera-modal.module';
import {LocationModule} from './components/location/location.module';
import {SelfieModule} from './components/selfie/selfie.module';
import {EatingHabitsModule} from './components/eating-habits/eating-habits.module';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {
    faCheckSquare,
    faCheckSquare as farCheckSquare,
    faExclamationCircle,
    faEye,
    faShareSquare,
    faSquare as farSquare,
    faSquare,
    faUtensils
} from '@fortawesome/free-solid-svg-icons';
import {IconModule} from './components/icon/icon.module';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';
import {ChildrenModule} from './pages/children/children.module';
import {FilterModule} from './components/filter/filter.module';
import {WomenModule} from './pages/women/women.module';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone';
import {ModalErrorComponent} from './components/modal-error/modal-error.component';
import {NavigationEndService} from './services/navigation-end.service';
import {SrsService} from './services/srs.service';
import {ProvinceService} from './services/province.service';
import {CountyService} from './services/county.service';
import {OrganizationService} from './services/organization.service';
import {DpsService} from './services/dps.service';
import {OrganizationFilterModule} from './components/organization-filter/organization-filter.module';
import {ModalDashboardUpdateComponent} from './components/modal-dashboard-update/modal-dashboard-update.component';
import {NewWidgetModule} from './layout/new-widget/widget.module';
import {PipeModule} from './pipes/pipe.module';
import { EvaluationList6Component } from './components/evaluation-list6/evaluation-list6.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { ModalConfirmWordComponent } from './components/modal-confirm-word/modal-confirm-word.component';
import { OrganizationUsersComponent } from './components/organization-users/organization-users.component';
import {CaseAvatarModule} from './components/case-avatar/case-avatar.module';
import {ModalChildSimilarCasesComponent} from './components/modal-child-similar-cases/modal-child-similar-cases.component';
import {ModalWomanSimilarCasesComponent} from './components/modal-woman-similar-cases/modal-woman-similar-cases.component';
import {OverallSimilarityModule} from './components/overall-similarity/overall-similarity.module';

const APP_PROVIDERS = [
    AppConfig,
    LoginService,
    AppGuard
];
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        ErrorComponent,
        ModalErrorComponent,
        ModalDashboardUpdateComponent,
        ModalChildSimilarCasesComponent,
        ModalWomanSimilarCasesComponent,
        EvaluationList6Component,
        ModalConfirmComponent,
        ModalConfirmWordComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        CameraModule,
        CameraModalModule,
        InfiniteScrollModule,
        HttpClientModule,
        IconModule,
        ToastrModule.forRoot(),
        NgxMaskModule.forRoot(),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.threeBounce,
            backdropBackgroundColour: 'rgba(0,0,0,0.5)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        // AngularFirestoreModule.enablePersistence(),
        AngularFireStorageModule,
        AngularFireMessagingModule,
        AngularFireAuthModule,
        LoginModule,
        LocationModule,
        FilterModule,
        OrganizationFilterModule,
        SelfieModule,
        WomenModule,
        VisitsModule,
        EatingHabitsModule,
        ChildrenModule,
        MuacModule,
        ValidationModule,
        AssistedByModule,
        TextListModule,
        PictureChangingModule,
        RouterModule.forRoot(ROUTES, {
            useHash: true,
            preloadingStrategy: PreloadAllModules,
            relativeLinkResolution: 'legacy',
            scrollPositionRestoration: 'enabled',
        }),
        TranslocoRootModule,
        NgbModule,
        NewWidgetModule,
        PipeModule,
        FontAwesomeModule,
        CaseAvatarModule,
        OverallSimilarityModule
    ], exports: [
    ],
    providers: [
        APP_PROVIDERS,
        {
            provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true
        },
        ScreenTrackingService,
        UserTrackingService
    ]
})
export class AppModule {

    constructor(private library: FaIconLibrary,
                private srsService: SrsService,
                private dpsService: DpsService,
                private provinceService: ProvinceService,
                private countyService: CountyService,
                private organizationService: OrganizationService,
                private navigationEndService: NavigationEndService) {
        library.addIcons(
            faSquare,
            faCheckSquare,
            farSquare,
            farCheckSquare,
            faSignOutAlt,
            faPhone,
            faExclamationCircle,
            faUtensils,
            faEye,
            faShareSquare);

        this.srsService.loadData();
        this.dpsService.loadData();
        this.provinceService.loadData();
        this.countyService.loadData();
        this.organizationService.loadData();
    }

}
