import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'filter-options',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

    childFilterOptions = [
        {
            id: 0,
            icon: 'hospital-symbol',
            text: 'Niño referido al hospital',
            field: 'icon.icon',
            class: 'hospital-icon'
        },
        {
            id: 1,
            icon: 'calendar-times',
            text: 'Tratamiento interrumpido',
            field: 'icon.icon',
            class: 'calendar-icon'
        },
        {
            id: 2,
            icon: 'exclamation-triangle',
            text: 'Sin progreso',
            field: 'icon.icon',
            class: 'exclamation-icon'
        },
        {
            id: 3,
            icon: 'share-square',
            text: 'Caso transferido',
            field: 'icon.icon',
            class: 'share-square-icon'
        },
        {
            id: 4,
            icon: 'healed',
            text: 'Niño recuperado',
            field: 'marker',
            class: 'healed'
        },
        {
            id: 5,
            icon: 'user-circle',
            text: 'Desnutrición aguda severa',
            field: 'marker',
            class: 'severe-acute-malnutrition'
        },
        {
            id: 6,
            icon: 'user-circle',
            text: 'Desnutrición aguda moderada',
            field: 'marker',
            class: 'moderate-acute-malnutrition'
        },
        {
            id: 7,
            icon: 'user-circle',
            text: 'Riesgo de desnutrición aguda',
            field: 'marker',
            class: 'risk-of-acute-malnutrition'
        },
        {
            id: 8,
            icon: 'user-circle',
            text: 'Niño fuera de la zona de riesgo',
            field: 'marker',
            class: 'outsite-risk-zone'
        }
    ];
    womanFilterOptions = [
        {
            id: 0,
            icon: 'hospital-symbol',
            text: 'Mujer referida al hospital',
            field: 'iconAlert.icon',
            class: 'hospital-icon'
        },
        {
            id: 1,
            icon: 'calendar-times',
            text: 'Tratamiento interrumpido',
            field: 'iconAlert.icon',
            class: 'calendar-icon'
        },
        {
            id: 2,
            icon: 'exclamation-triangle',
            text: 'Sin progreso',
            field: 'iconAlert.icon',
            class: 'exclamation-icon'
        },
        {
            id: 3,
            icon: 'share-square',
            text: 'Caso transferido',
            field: 'icon.icon',
            class: 'share-square-icon'
        },
        {
            id: 4,
            icon: 'healed',
            text: 'Mujer recuperada',
            field: 'marker',
            class: 'healed'
        },
        {
            id: 5,
            icon: 'user-circle',
            text: 'Desnutrición aguda',
            field: 'marker',
            class: 'acute-malnutrition'
        },
        {
            id: 6,
            icon: 'user-circle',
            text: 'Mujer fuera de la zona de riesgo',
            field: 'marker',
            class: 'outsite-risk-zone outside-risk-zone'
        }
    ];
    @Output() selected: EventEmitter<any> = new EventEmitter();
    public selectedOption: number;

    @Output()
    public quitarFiltros = new EventEmitter<void>();

    @Input()
    public type: string;

    constructor(private modalService: NgbModal) {
    }

    close(modal) {
        modal.close();
    }

    get options() {
        return (this.type && this.type == 'woman') ? this.womanFilterOptions : this.childFilterOptions;
    }

    apply(modal) {
        const option = this.options.filter(item => item.id == this.selectedOption)[0];
        if (option && option.field == 'marker') {
            option.icon = option.class;
        }
        this.selected.emit(option);
        this.close(modal);
    }

    open(content) {
        this.modalService.open(content, {
            ariaLabelledBy: 'filter',
            centered: true,
        }).result.then((closeAction) => {
            console.log(closeAction);
            if (closeAction) {
                closeAction();
            }
        });
    }

    ngOnInit(): void {
    }

    cleanFilter(modal){
        this.quitarFiltros.emit();
        modal.close();
    }

}
