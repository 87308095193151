import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'text-list',
    templateUrl: './text-list.component.html',
    styleUrls: ['./text-list.component.scss']
})
export class TextListComponent implements OnInit {

    @Input()
    public title: string;
    @Input()
    public content: Array<string>;
    @Input()
    public translate: boolean;

    constructor() {
    }

    ngOnInit() {
    }

}
