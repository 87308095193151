import {ChildCaseModel} from './child-case.model';

export class ChildInfoModel {

    public childToken: string;
    public name: string;
    public muac: number;
    public province: string;
    public county: string;
    public organization: string;
    public healthAssistantUID: string;
    public marker: string;
    public alert: string;
    public gender: string;
    public hospitalCase: boolean;
    public firstVisit: Date;
    public transferredCase: boolean;

    constructor(childCase: ChildCaseModel) {
        this.childToken = childCase.childToken;
        this.name = childCase.childName;
        this.gender = childCase.gender;
        this.muac = childCase.muac;
        this.province = childCase.province;
        this.county = childCase.county;
        this.organization = childCase.organizationName;
        this.healthAssistantUID = childCase.assistantUid;
        this.marker = childCase.marker;
        this.hospitalCase = childCase.childReferredToTheHospital;
        this.transferredCase = childCase.transferredCase;
        this.alert = childCase.alert?.icon;
        this.firstVisit = childCase.date;
    }

}
