export class ModalModel {

    modal: {
        title: string;
        status?: string;
        altTitle?: string;
        message: string;
        altMessage?: string;
        img?: string;
        options?: Array<{
            text: string;
            img: string;
            action: any;
        }>,
        primaryButton?: {
            text: string;
            action: any,
        },
        secondaryButton?: {
            text: string;
            action: any,
        }
    };
    btn: {
        text: string;
        styles: string;
    };
}
