import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ChildInfoModel} from '../model/child-info.model';
import {ChildCaseModel} from '../model/child-case.model';
import {ChildPersonalInfo} from '../model/child-personal-info';
import {WomanInfo} from '../model/woman-info';
import {UserInfoModel} from '../model/user-info.model';
import {User} from '../model/user.model';
import firebase from 'firebase';
import FieldValue = firebase.firestore.FieldValue;
import {Dashboard} from '../model/dashboard';
import {createGunzip} from 'zlib';
import {WomanCaseModel} from '../model/woman-case.model';
import {ParseObjectUtil} from '../util/parse-object.util';

// const algoliasearch = require('algoliasearch');

@Injectable({
    providedIn: 'root'
})
export class GeneralUpdateService {

    constructor(private db: AngularFirestore) {
    }

    dashboard: Dashboard;

    collection = {
        key: '',
        dashboard: Dashboard
    };

    check() {
    }

    public listChildrenGenre(): Promise<string> {
        return new Promise<string>(resolve => {
            const users = new Array<ChildPersonalInfo>();
            this.db.collection('children').get().forEach(element => {
                element.forEach(data => {
                    const child = data.data() as ChildCaseModel;
                    users.push(new ChildPersonalInfo(child));
                });
            }).then(() => {
                resolve(this.generateChildCSV(users));
            });
        });
    }

    public listUsers(): Promise<string> {
        return new Promise<string>(resolve => {
            const users = new Array<UserInfoModel>();
            this.db.collection('users').get().forEach(element => {
                element.forEach(data => {
                    const user = data.data() as User;
                    users.push(new UserInfoModel(user));
                });
            }).then(() => {
                resolve(this.generateUserCSV(users));
            });
        });
    }

    public listWomen(): Promise<string> {
        return new Promise<string>(resolve => {
            const women = new Array<WomanInfo>();
            this.db.collection('womanCases').get().forEach(element => {
                element.forEach(data => {
                    const woman = data.data() as WomanCaseModel;
                    women.push(new WomanInfo(woman));
                });
            }).then(() => {
                resolve(this.generateWomanCSV(women));
            });
        });
    }

    public listChildren(): Promise<string> {
        return new Promise<string>(resolve => {
            const children = new Array<ChildInfoModel>();
            this.db.collection('cases').get().forEach(element => {
                element.forEach(data => {
                    const child = data.data() as ChildCaseModel;
                    children.push(new ChildInfoModel(child));
                });
            }).then(() => {
                resolve(this.generateCasesCSV(children));
            });
        });
    }

    unknown(childCase) {
        return 'No Identificado';
    }

    public updateDashboard() {
        const collection = [];
        collection.push(
            {
                key: '',
                dashboard: new Dashboard()
            });

        this.db.collection('cases').get().subscribe(value => {
            for (let i = 0; i < value.docs.length; i++) {

                this.dashboard = new Dashboard();
                const childCase = value.docs[i].data() as ChildCaseModel;
                if (!childCase.organizationName) {
                    console.log(childCase.token);
                }
            }
        });
    }

    isWithoutProgress(childCase) {
        return childCase.iconAlert && childCase.iconAlert.icon === 'exclamation-triangle';
    }

    wasTheTreatmentInterrupted(childCase) {
        return childCase.iconAlert && childCase.iconAlert.icon === 'calendar-times';
    }


    private updateQuery(dashboard) {
        return {
            total: FieldValue.increment(dashboard.total),
            hospitalized: FieldValue.increment(dashboard.hospitalized),
            interruptedTreatment: FieldValue.increment(dashboard.interruptedTreatment),
            noProgress: FieldValue.increment(dashboard.noProgress),
            transferred: FieldValue.increment(dashboard.transferred),
            severeAcuteMalnutrition: FieldValue.increment(dashboard.severeAcuteMalnutrition),
            moderateAcuteMalnutrition: FieldValue.increment(dashboard.moderateAcuteMalnutrition),
            healed: FieldValue.increment(dashboard.healed),
            outsideRiskZone: FieldValue.increment(dashboard.outsideRiskZone),
            noMeasured: FieldValue.increment(dashboard.noMeasured),
        };
    }

    public updateUserInfo() {
        const cases = new Array<ChildInfoModel>();
        this.db.collection('children').get().forEach(element => {
            element.forEach(data => {
                const woman = data.data() as ChildCaseModel;
                cases.push(new ChildInfoModel(woman));
            });
        }).then(() => {
            this.generateCasesCSV(cases);
        });
    }


    public generateWomanCSV(cases: Array<WomanInfo>): string {
        let csv = '\r\n';
        for (let i = 0; i < cases.length; i++) {
            let line = '';
            for (const index in cases[i]) {
                if (line != '') {
                    line += ';';
                }

                line += cases[i][index];
            }
            csv += line + '\r\n';
        }

        return csv;
    }

    public generateUserCSV(cases: Array<UserInfoModel>): any {
        let csv = '\r\n';
        for (let i = 0; i < cases.length; i++) {
            let line = '';
            // tslint:disable-next-line:forin
            for (const index in cases[i]) {
                if (line != '') {
                    line += ';';
                }

                line += cases[i][index];
            }
            csv += line + '\r\n';
        }

        return csv;
    }

    public generateChildCSV(cases: Array<ChildPersonalInfo>): string {
        let csv = '\r\n';
        for (let i = 0; i < cases.length; i++) {
            let line = '';
            for (const index in cases[i]) {
                if (line != '') {
                    line += ';';
                }

                line += cases[i][index];
            }
            csv += line + '\r\n';
        }

        return csv;
    }

    public generateCasesCSV(cases: Array<ChildInfoModel>): string {
        let csv = '\r\n';
        for (let i = 0; i < cases.length; i++) {
            let line = '';
            for (const index in cases[i]) {
                if (line != '') {
                    line += ';';
                }

                line += cases[i][index];
            }
            csv += line + '\r\n';
        }

        return csv;
    }

    public generateCasesCSVEatingHabits(cases: Array<Habits>) {
        let csv = '\r\n';
        for (let i = 0; i < cases.length; i++) {
            let line = '';
            for (const index in cases[i]) {
                if (line != '') {
                    line += ';';
                }

                line += cases[i][index];
            }
            csv += line + '\r\n';
        }

        console.log(csv);
    }

    public listEatingHabits() {
        const children = new Array<Habits>();
        this.db.collection('womanCases', ref => ref.where('marker', '==', 'healed')).get().forEach(element => {
            element.forEach(data => {
                const child = data.data() as WomanCaseModel;
                children.push(new Habits(child));
            });
        }).then(() => {
            this.generateCasesCSVEatingHabits(children);
        });
    }

}

export class Habits {
    token: string;
    name: string;
    visit1Answer1: string;
    visit1Answer2: string;
    visit1Answer3: string;
    visit1Answer4: string;
    visit1Answer5: string;
    visit1Answer6: string;
    visit1Answer7: string;
    visit1Score: number;
    visit2Answer1: string;
    visit2Answer2: string;
    visit2Answer3: string;
    visit2Answer4: string;
    visit2Answer5: string;
    visit2Answer6: string;
    visit2Answer7: string;
    visit2Score: number;

    constructor(aCase: WomanCaseModel) {
        this.token = aCase.token;
        this.name = aCase.firstName + ' ' + aCase.lastName;
        this.visit1Answer1 = aCase.eatingHabits[0]?.answer1;
        this.visit1Answer2 = aCase.eatingHabits[0]?.answer2;
        this.visit1Answer3 = aCase.eatingHabits[0]?.answer3;
        this.visit1Answer4 = aCase.eatingHabits[0]?.answer4;
        this.visit1Answer5 = aCase.eatingHabits[0]?.answer5;
        this.visit1Answer6 = aCase.eatingHabits[0]?.answer6;
        this.visit1Answer7 = aCase.eatingHabits[0]?.answer7;
        this.visit1Score = aCase.eatingHabits[0]?.points;
        // -------------------------------------------------
        this.visit2Answer1 = aCase.eatingHabits[1]?.answer1;
        this.visit2Answer2 = aCase.eatingHabits[1]?.answer2;
        this.visit2Answer3 = aCase.eatingHabits[1]?.answer3;
        this.visit2Answer4 = aCase.eatingHabits[1]?.answer4;
        this.visit2Answer5 = aCase.eatingHabits[1]?.answer5;
        this.visit2Answer6 = aCase.eatingHabits[1]?.answer6;
        this.visit2Answer7 = aCase.eatingHabits[1]?.answer7;
        this.visit1Score = aCase.eatingHabits[0]?.points;
        this.visit2Score = aCase.eatingHabits[1]?.points;

    }
}
