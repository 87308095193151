import {Input, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelfieComponent} from './selfie.component';

@NgModule({
    declarations: [SelfieComponent],
    imports: [
        CommonModule
    ], exports: [SelfieComponent]
})
export class SelfieModule {

    @Input()
    private picture: '/assets/avatar/child.svg';

    constructor() {
    }

}
