import {OngModel} from './ong.model';

export class UnapModel {

    public id: number;
    public county: number;
    public countyReference: string;
    public name: string;
    public provinceReference: string;

    constructor(ong?: OngModel) {
        if (ong) {
            this.id = ong.id;
            this.provinceReference = ong.provinceReference;
            this.name = ong.name;
        }
    }

}
