import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssistedByComponent} from './assisted-by.component';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
    declarations: [AssistedByComponent],
    imports: [
        CommonModule,
        TranslocoModule,
    ],
    exports: [
        AssistedByComponent
    ]
})
export class AssistedByModule {
}
