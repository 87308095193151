import {User} from './user.model';
import {TreatmentActionModel} from './treatment-action.model';

export class VisitModel {

    order: number;
    marker: string;
    muac: number;
    date: Date;
    type: string;
    gestationWeek: number;
    nextVisit: string;
    result: any;
    comments: string;
    alert: string;
    stages: Array<string>;
    symptoms: Array<string>;
    anotherDisease: string;
    instructions: Array<string>;
    give: TreatmentActionModel;
    dosis: TreatmentActionModel;
    takeChildToHospital: TreatmentActionModel;
    healthAssistant: User;
    fowardToHospital: boolean;
    hospitalName: string;
    hospitalNumber: string;
    referToHospital: boolean;
    alertIcon: any;
    appVersion: string;

    completed = false;

    constructor(date?: Date, marker?: string) {
        this.marker = marker;
        this.date = date;
    }

}
