import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import algoliasearch from 'algoliasearch';
import {AlgoliaUtil} from '../../util/algolia.util';
import {UnapModel} from '../../model/unap.model';

@Component({
    selector: 'organization-filter',
    templateUrl: './organization-filter.component.html',
    styleUrls: ['./organization-filter.component.scss']
})
export class OrganizationFilterComponent implements OnInit {

    @Output() selected: EventEmitter<string> = new EventEmitter();
    public searchBy: string;
    index;
    results: UnapModel[];

    constructor(private modalService: NgbModal) {
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getSearchApiKey());
        this.index = client.initIndex(AlgoliaUtil.getOrganizationUniqueIndex());
    }

    close(modal) {
        modal.close();
    }

    get options() {
        return null;
    }

    async search() {
        if (this.searchBy?.length === 0) {
            this.results = [];
            return;
        }
        await this.index.search(this.searchBy, {
            hitsPerPage: 15
        }).then(data => {
            this.results = data.hits;
        });
    }

    apply(modal, name) {
        this.selected.emit(name);
        this.close(modal);
    }

    open(content) {
        this.modalService.open(content, {
            ariaLabelledBy: 'filter',
            centered: true,
        }).result.then((closeAction) => {
            if (closeAction) {
                closeAction();
            }
        });
    }

    ngOnInit(): void {
    }

}
