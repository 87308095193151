import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EatingHabitsComponent} from './eating-habits.component';
import {TranslocoModule} from '@ngneat/transloco';
import {FormsModule} from '@angular/forms';


@NgModule({
    declarations: [EatingHabitsComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule
    ], exports: [EatingHabitsComponent]
})
export class EatingHabitsModule {
}
