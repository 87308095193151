import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocationComponent} from './location.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidationModule} from '../validation/validation.module';

@NgModule({
    declarations: [LocationComponent],
    imports: [
        CommonModule,
        FormsModule,
        ValidationModule,
        ReactiveFormsModule
    ], exports: [LocationComponent]
})
export class LocationModule {
}
