import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from '../icon/icon.module';
import {FormsModule} from '@angular/forms';
import {OrganizationFilterComponent} from './organization-filter.component';

@NgModule({
    declarations: [OrganizationFilterComponent],
    imports: [
        CommonModule,
        IconModule,
        FormsModule
    ], exports: [OrganizationFilterComponent]
})
export class OrganizationFilterModule {
}
