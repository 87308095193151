export class CountyModel {

    id: number;
    name: string;
    province: number;
    ong: number;
    provinceReference: string;

    public constructor(private object: any) {
        this.id = object.id;
        this.name = object.name;
        this.province = object.province;
        this.ong = object.ong;
        this.provinceReference = object.provinceReference;
    }

    get idAsString() {
        return this.id.toString();
    }

}
