import { Injectable } from '@angular/core';
import { ProvinceModel } from '../model/province.model';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'lodash';
import { SrsService } from './srs.service';
import { LoginService } from './login.service';
import { DpsService } from './dps.service';

@Injectable({
    providedIn: 'root'
})
export class ProvinceService {

    private data = new Array<ProvinceModel>();

    constructor(private db: AngularFirestore,
        private srsService: SrsService,
        private dpsService: DpsService,
        private loginService: LoginService) {
        this.loadData();
    }


    public async listByProfile(): Promise<Array<ProvinceModel>> {

        if (this.loginService.isCurrentUserUnicefOrSNS()) {
            return this.listAll();
        }

        if (this.loginService.isCurrentUserDpsSupervisor()) {
            return await this.getFilterDPSSuperviros();
        }

        if (this.loginService.isCurrentUserSrsSupervisor()) {
            return await this.getFilterSRSSupervisor();
        }

        return this.listAll();
    }


    private async getFilterDPSSuperviros(): Promise<Array<ProvinceModel>> {
        const user = this.loginService.getCurrentUser();
        const dps = user.dps ? user.dps : await this.dpsService.searchByName(user.organizationName);
        const province = await this.loadById(dps.province);
        return [province];
    }

    private async getFilterSRSSupervisor(): Promise<Array<ProvinceModel>> {
        const user = this.loginService.getCurrentUser();
        const srs = user.srs ? user.srs : await this.srsService.searchByName(user.organizationName);
        const provinces = await this.searchBySrs(srs.id);
        return provinces;
    }


    listAll() {
        return this.data.sort(this.compare);
    }

    listByOrganization(srs: number) {

        if (!srs) {
            return;
        }

        const arr = this.listAll();

        return this.listAll()
            .filter(object => object.srs == srs)
            .map(result => result.name)
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter((e) => arr[e]).map(e => arr[e])
            .map(v => v.name);

    }

    async searchBySrs(srs: number): Promise<Array<ProvinceModel>> {
        return this.db.firestore.collection('provinces').where('srs', '==', srs).get().then(snp => snp.docs.map(doc => doc.data() as ProvinceModel));
    }

    async loadById(provinceId: number): Promise<ProvinceModel> {
        return this.db.firestore.collection('provinces').where('id', '==', provinceId).get().then(snp => snp.docs[0].data() as ProvinceModel);
    }

    private compare(province1, province2) {

        let comparison = 0;

        if (province1.name > province2.name) {
            comparison = 1;
        } else if (province1.name < province2.name) {
            comparison = -1;
        }

        return comparison;

    }

    public findSRS(province: string) {
        return this.data
            .filter(value => value.name == province)
            .map(value => this.srsService.getByCode(value.srs))[0];
    }

    public loadData() {
        this.db.firestore.collection('provinces')
            .onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const newData = change.doc.data() as ProvinceModel;
                    const index = _.findIndex(this.data, (obj) => _.isEqual(obj, newData));
                    if (index === -1) {
                        this.data.push(newData);
                    }
                });
            });
    }

}
