import {Injectable} from '@angular/core';
import {EatingHabitModel} from '../model/eating-habit.model';
import {TreatmentCase} from '../model/treatment-case';

@Injectable({
    providedIn: 'root'
})
export class EatingHabitsService {

    constructor() {
    }

    private quizOrder = 0;
    private childEatingHabits: EatingHabitModel;
    private womanEatingHabits: EatingHabitModel;

    get childEvaluation() {
        return this.childEatingHabits;
    }

    get womanEvaluation() {
        return this.womanEatingHabits;
    }

    public evaluateChild(quiz: any) {
        this.childEatingHabits = new EatingHabitModel((this.quizOrder > 0 ? 2 : 1) as number, quiz);
    }

    public evaluateWoman(quiz: any) {
        this.womanEatingHabits = new EatingHabitModel((this.quizOrder > 0 ? 2 : 1) as number, quiz);
    }

    public addEatingHabits(aCase: TreatmentCase, eatingHabits: EatingHabitModel) {
        if (eatingHabits) {
            let order: number = aCase.visits.filter(visit => visit.date).length || 0;
            eatingHabits.order = order++;
            aCase.eatingHabits.push(eatingHabits);
        }
    }

}
