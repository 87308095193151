export class ButtonActionModel {
    text: string;
    action: any;

    constructor(text?: string, action?: any) {
        this.text = text;
        this.action = action;
    }

}
