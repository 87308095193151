import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {WomanCaseService} from '../../services/woman-case.service';
import {Router} from '@angular/router';
import {WomanSimilarCaseResponse} from '../../model/similar-cases/woman/woman-similar-case-response';
import {WomanSimilarCaseUserDecision} from '../../model/similar-cases/woman/woman-similar-case-user-decision';
import {WomanSimilarCaseDetected} from '../../model/similar-cases/woman/woman-similar-case-detected';
import {WomanUtil} from '../../util/woman.util';

@Component({
    selector: 'modal-woman-similar-cases',
    templateUrl: './modal-woman-similar-cases.component.html',
    styleUrls: ['./modal-woman-similar-cases.component.scss']
})
export class ModalWomanSimilarCasesComponent implements OnInit {
    @Input() data!: WomanSimilarCaseResponse;
    protected readonly confirm = confirm;

    constructor(public activeModal: NgbActiveModal,
                public womanUtils: WomanUtil,
                public womanCaseService: WomanCaseService,
                private router: Router) {
    }

    showWomanProfile(selectedCase: WomanSimilarCaseDetected) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['app/woman-profile/', selectedCase.objectID])
        );
        const baseUrl = window.location.origin.replace(/\/$/, '');
        sessionStorage.setItem('openWomanProfileOnPopup', selectedCase.objectID);
        window.open(`${baseUrl}/#${url}`, '_blank');
    }

    markAsNoSimilar() {
        const userDecision = new WomanSimilarCaseUserDecision();
        userDecision.markedAsNoSimilar = true;
        this.activeModal.close(userDecision);
    }

    get modalTitle(): string {
        const caseCount = this.data?.cases?.length || 0;
        if (caseCount === 0) {
            return 'Casos similares';
        }
        if (caseCount === 1) {
            return 'Identificamos 1 caso similar';
        }
        return `Identificamos ${caseCount} casos similares`;
    }

    ngOnInit(): void {
    }

}
