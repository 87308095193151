export class EatingHabitModel {

    public order: number;
    public answer1: string;
    public answer2: string;
    public answer3: string;
    public answer4: string;
    public answer5: string;
    public answer6: string;
    public answer7: string;
    public points: number;
    public date = new Date();

    constructor(order?: number, quiz?: any) {
        this.order = order;
        this.answer1 = quiz[0].answer;
        this.answer2 = quiz[1].answer;
        this.answer3 = quiz[2].answer;
        this.answer4 = quiz[3].answer;
        this.answer5 = quiz[4].answer;
        this.answer6 = quiz[5].answer;
        if (quiz.length > 6) {
            this.answer7 = quiz[6].answer;
        }
        this.points = 0;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < quiz.length; i++) {
            this.points += quiz[i].calc();
        }
    }
}
