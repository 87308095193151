import {Component, Input, OnInit} from '@angular/core';
import {
    faHospitalSymbol,
    faFilter,
    faTrash,
    faCalendarTimes,
    faShareSquare,
    faExclamationTriangle,
    faUserCheck,
    faUserCircle,
    faExclamationCircle, faUtensils, faEye, faEyeSlash, faSearch
} from '@fortawesome/free-solid-svg-icons';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone';

@Component({
    selector: 'icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

    @Input()
    value: string;
    @Input()
    class: string;

    constructor() {
    }

    get showIcon() {
        if (!this.value) {
            return;
        }
        switch (this.value) {
            case 'hospital-symbol':
                return faHospitalSymbol;
            case 'filter':
                return faFilter;
            case 'trash':
                return faTrash;
            case 'calendar-times':
                return faCalendarTimes;
            case 'exclamation-triangle':
                return faExclamationTriangle;
            case 'share-square':
                return faShareSquare;
            case 'healed':
                return faUserCheck;
            case 'user-circle':
                return faUserCircle;
            case 'logout':
                return faSignOutAlt;
            case 'phone':
                return faPhone;
            case 'alert-error':
                return faExclamationCircle;
            case 'eating-habits':
                return faUtensils;
            case 'eye':
                return faEye;
            case 'eye-block':
                return faEyeSlash;
            case 'search':
                return faSearch;
        }
    }

    ngOnInit(): void {
    }

}
