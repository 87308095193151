import firebase from 'firebase';
import WhereFilterOp = firebase.firestore.WhereFilterOp;

export class FilterUtil {

    public field: string;
    public option: WhereFilterOp;
    public value: any;


    constructor(field: string, option: WhereFilterOp, value: any) {
        this.field = field;
        this.option = option;
        this.value = value;
    }

}
