import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CaseAvatarComponent} from './case-avatar.component';

@NgModule({
    declarations: [CaseAvatarComponent],
    imports: [
        CommonModule
    ],
    exports: [CaseAvatarComponent]
})
export class CaseAvatarModule {
}
