import {User} from './user.model';

export class UserInfoModel {

    uid: string;
    name: string;
    email: string;
    identityCard: string = '';
    organizationName: string;
    organizationInitials: string;
    organizationType: string;
    phone: string = '';
    province: string;
    county: string;
    serviceType: string;

    constructor(user?: User) {
        this.uid = user.uid;
        this.name = user.firstName + ' ' + user.lastName;
        this.email = user.email;
        this.identityCard = user.identityCard;
        this.organizationName = user.organizationName;
        this.organizationInitials = user.organizationInitials;
        this.organizationType = user.organizationType;
        this.phone = user.phone;
        this.province = user.province;
        this.county = user.county;
        this.serviceType = user.serviceType;
    }

}
