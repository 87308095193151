import moment from 'moment';

export class DateUtil {

    static monthsDiff(d1: Date, d2: Date) {
        return moment(d2).diff(moment(d1), 'months');
    }

    static daysDiff(d1: any, d2: Date) {
        const d1AsDate = moment(d1, 'DD/MM/YYYY').toDate();
        const date1 = new Date(d1AsDate);
        const date2 = new Date(d2);
        const diff = date2.getTime() - date1.getTime();
        return diff / (1000 * 3600 * 24);
    }

    static yearsDiff(d1, d2) {
        return moment(d2).diff(moment(d1), 'years');
    }

    static toDate(dateAsString: string) {
        if (dateAsString == null) {
            return null;
        }
        const parts: string[] = dateAsString.split('/');
        const year: number = Number(parts[2]);
        const month: number = Number(parts[1]) - 1;
        const day: number = Number(parts[0]);
        let date = new Date(year, month, day);

        return date;
    }

    static currentMinusFirstTimestampOfDay(value, type) {
        const today = moment().utc().hours(0).minutes(0).seconds(0).milliseconds(1);
        if (value > 0) {
            return today.subtract(value, type).valueOf();
        }
        return today.valueOf();
    }

    static currentMinusLastTimestampOfDay(value, type) {
        const today = moment().utc().hours(23).minutes(59).seconds(59).milliseconds(999);
        if (value > 0) {
            return today.subtract(value, type).valueOf();
        }
        return today.valueOf();
    }


    static todayFirstTimestampOfDay() {
        const today = moment().utc().hours(0).minutes(0).seconds(0).milliseconds(0);
        return today.valueOf();
    }

    static todayLastTimestampOfDay() {
        const today = moment().utc().hours(23).minutes(59).seconds(59).milliseconds(999);
        return today.valueOf();
    }

    static tomorrow() {
        const today = new Date();
        DateUtil.addDays(today, 1);
        return today.valueOf();
    }

    static nowTimestamp() {
        return moment().utc().valueOf();
    }

    static nowFormated(format: string) {
        return moment().utc().format(format);
    }

    static toTimestamp(input: string) {
        const parts: string [] = input.match(/(\d+)/g);
        return new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2])).getTime();
    }

    static format(timestamp: number, format: string) {
        return moment(timestamp).utc().format(format);
    }

    static toMoment(dateStr: string, format: string) {
        return moment(dateStr, format).utc();
    }

    static addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    static minusDays(date: Date, days: number): Date {
        date.setDate(date.getDate() - days);
        return date;
    }

    public static getFirstDayOfMonth() {
        const now = new Date();
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        return firstDay;
    }

    public static getLastDayOfMonth() {
        const now = new Date();
        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        return lastDay;
    }

    public static getFirstDayOfYear() {
        return new Date(new Date().getFullYear(), 0, 1);
    }

    public static getLastDayOfYear() {
        return new Date(new Date().getFullYear(), 11, 31);
    }

    public static getFirstDayOfLastYear() {
        return new Date(new Date().getFullYear() - 1, 0, 1);
    }

    public static getLastDayOfLastYear() {
        return new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59);
    }

}
