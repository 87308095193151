import {Injectable} from '@angular/core';
import {ChildCaseModel} from '../model/child-case.model';
import {PictureService} from './picture.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ParseObjectUtil} from '../util/parse-object.util';
import {LoginService} from './login.service';
import {User} from '../model/user.model';
import {Observable} from 'rxjs/Observable';
import {DateUtil} from '../util/date.util';
import {SortUtil} from '../util/sort.util';
import {FilterUtil} from '../util/filter.util';
import {ChildModel} from '../model/child.model';
import * as _ from 'lodash';
import {AlgoliaService} from './algolia.service';
import algoliasearch, {SearchIndex} from 'algoliasearch';
import {AlgoliaUtil} from '../util/algolia.util';

@Injectable({
    providedIn: 'root'
})
export class ChildCaseService {

    static STEP_PROGRESS: number = 25;
    static FIRST_STEP: number = 1;
    public childCase: ChildCaseModel;
    public childCaseEdit: ChildCaseModel;
    public childCaseDelegate: ChildCaseModel;
    public registryProgress: number = 25;
    public currentStep: number = 1;
    public user: User;
    public newCase = true;
    public availableWizard: any[];
    private index: SearchIndex;

    public wizard = [
        {
            step: 1,
            active: this.registryProgress <= 25,
            progress: 25,
            title: '1. Datos de la visita'
        },
        {
            step: 2,
            active: this.registryProgress <= 50 && this.registryProgress > 25,
            progress: 50,
            title: '2. Datos de registro'
        },
        {
            step: 3,
            active: this.registryProgress <= 75 && this.registryProgress > 50,
            progress: 75,
            title: '3. Sintomas'
        },
        {
            step: 4,
            active: this.registryProgress <= 100 && this.registryProgress > 75,
            progress: 100,
            title: '4. Resultados'
        }
    ];

    public newVisitWizard = [
        {
            step: 1,
            active: this.registryProgress <= 33,
            progress: 33,
            title: '1. Datos de la visita'
        },
        {
            step: 2,
            active: this.registryProgress <= 66 && this.registryProgress > 33,
            progress: 66,
            title: '2. Sintomas'
        },
        {
            step: 3,
            active: this.registryProgress <= 100 && this.registryProgress > 66,
            progress: 100,
            title: '3. Resultados'
        }
    ];

    constructor(private pictureService: PictureService,
                private loginService: LoginService,
                private algoliaService: AlgoliaService,
                private db: AngularFirestore) {
        this.user = loginService.currentUser;
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getSearchApiKey());
        this.index = client.initIndex(AlgoliaUtil.getChildrenIndex());
    }

    getAge(date: Date) {
        return DateUtil.yearsDiff(date, new Date());
    }

    getMonths(date: Date) {
        return DateUtil.monthsDiff(date, new Date());
    }

    newVisit(childCase: ChildCaseModel) {
        this.childCase = childCase;
        this.childCase.transferredCase = false;
        this.childCase.muac = null;
        this.childCase.date = null;
        this.childCase.comments = null;

        this.registryProgress = 33;
        this.availableWizard = _.cloneDeep(this.newVisitWizard);
        this.currentStep = 1;
    }

    initCase() {
        if (!this.childCase) {
            console.log('Iniciando um novo caso');
            this.childCase = new ChildCaseModel(this.loginService.currentUser);
            this.availableWizard = this.wizard;
        }
    }

    findMyCases() {
        return this.db.collection<ChildCaseModel>('cases',
            ref => ref.where('healthAssistantsUids', 'array-contains', this.loginService.authUser?.uid)
                .orderBy('nextVisitDate', 'asc')).valueChanges();
    }

    find(sortBy?: SortUtil, filterBy?: FilterUtil) {
        if (!sortBy) {
            sortBy = new SortUtil('nextVisitDate', 'asc');
        }

        if (this.loginService.authUser.srs) {
            return this.findForSRS(sortBy, filterBy);
        } else if (this.loginService.authUser.organizationType?.includes('UNICEF')) {
            return this.findAll(sortBy, filterBy);
        } else if (this.loginService.authUser.dps) {
            return this.findForDPS(sortBy, filterBy);
        } else {
            return this.findOrganizationCases(sortBy, filterBy);
        }
    }

    // List by organization
    findOrganizationCases(sortBy: SortUtil, filterBy?: FilterUtil): Observable<ChildCaseModel[]> {
        if (filterBy) {
            console.log(filterBy);
            return this.db.collection<ChildCaseModel>('cases',
                ref => ref.where('organizationName', '==', this.loginService.authUser?.organizationName)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)).valueChanges();
        }

        return this.db.collection<ChildCaseModel>('cases',
            ref => ref.where('organizationName', '==', this.loginService.authUser?.organizationName)
                .orderBy(sortBy.field, sortBy.direction)).valueChanges();
    }

    // List for national
    findAll(sortBy: SortUtil, filterBy?: FilterUtil) {
        if (filterBy) {
            return this.db.collection<ChildCaseModel>('cases',
                ref => ref.where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)).valueChanges();
        }

        return this.db.collection<ChildCaseModel>('cases',
            ref => ref.orderBy(sortBy.field, sortBy.direction)).valueChanges();
    }

    // List for SRS
    findForSRS(sortBy: SortUtil, filterBy?: FilterUtil) {
        const provinces = this.loginService.provinces.map(province => province.name);
        if (provinces.length === 0) {
            provinces.push('EMPTY');
        }
        return this.db.collection<ChildCaseModel>('cases',
            ref => ref.where('province', 'in', provinces).orderBy(sortBy.field, sortBy.direction)).valueChanges();
    }

    // List for SRS
    findForDPS(sortBy: SortUtil, filterBy?: FilterUtil) {
        const province = this.loginService.authUser.dps.provinceReference;
        if (filterBy) {
            return this.db.collection<ChildCaseModel>('cases',
                ref => ref.where('province', '==', province)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)).valueChanges();
        }

        return this.db.collection<ChildCaseModel>('cases',
            ref => ref.where('province', '==', province).orderBy(sortBy.field, sortBy.direction)).valueChanges();
    }

    cleanCaseSpaces() {
        const propsCaseToClean = [
            'birthCertificateNumber',
            'caregiverName',
            'childAddress',
            'chronicDisease',
            'firstName',
            'lastName',
            'mothersName',
        ];

        propsCaseToClean.forEach(props => {
            if (this.childCase[props]) {
                this.childCase[props] = this.childCase[props].trim();
                this.childCase[props] = this.childCase[props].replace(/\s\s+/g, ' ');
            }
        });
    }

    async save(): Promise<void> {
        this.childCase.updateDate = new Date();
        this.childCase.appVersion = 'WEB';
        for (let i = 0; i < this.childCase.visits.length; i++) {
            if (this.childCase.visits[i].marker != 'no-measured' && !this.childCase.visits[i].date) {
                this.childCase.visits[i].date = this.childCase.date;
            }
        }
        this.cleanCaseSpaces();

        if (this.childCase.visits.filter(visit => visit.date).length > 1) {
            return this.update();
        } else {
            if (this.childCase.childSelfie && this.childCase.childSelfie.includes('data')) {
                await fetch(this.childCase.childSelfie).then(async response => {
                    this.childCase.childSelfieRef = await this.pictureService.upload(await response.blob());
                    this.childCase.childSelfie = await this.pictureService.getPictureURL(this.childCase.childSelfieRef) as string;
                    return this.caseRegistry();
                });
            } else {
                this.childCase.childSelfie = await this.pictureService.getPictureURL(PictureService.NO_PROFILE_PICTURE) as string;
                return this.caseRegistry();
            }
        }
    }

    caseRegistry() {
        if (!this.childCase.childName) {
            this.childCase.childName = this.childCase.firstName + ' ' + this.childCase.lastName;
            this.childCase.childToken = this.childCase.token;
        }
        const child = new ChildModel();
        child.updateWithCase(this.childCase);
        this.db.collection('children').doc(this.childCase.token).set(ParseObjectUtil.parse(child));

        return this.db.collection('cases').doc(this.childCase.token).set(ParseObjectUtil.parse(this.childCase));
    }

    update(childCase?: ChildCaseModel) {
        if (childCase) {
            return this.db.collection<ChildCaseModel>('cases').doc(childCase.token)
                .set(ParseObjectUtil.parse(childCase), {merge: true});
        }
        return this.db.collection<ChildCaseModel>('cases').doc(this.childCase.token)
            .set(ParseObjectUtil.parse(this.childCase), {merge: true});
    }

    async edit() {
        if (this.childCaseEdit.childSelfie && this.childCaseEdit.childSelfie.includes('data')) {
            await fetch(this.childCaseEdit.childSelfie).then(async response => {
                this.childCaseEdit.childSelfieRef = await this.pictureService.upload(await response.blob());
                this.childCaseEdit.childSelfie = await this.pictureService.getPictureURL(this.childCaseEdit.childSelfieRef) as string;

                return this.db.collection<ChildCaseModel>('cases').doc(this.childCaseEdit.token)
                    .set(ParseObjectUtil.parse(this.childCaseEdit), {merge: true});
            });
        }
        return this.db.collection<ChildCaseModel>('cases').doc(this.childCaseEdit.token)
            .set(ParseObjectUtil.parse(this.childCaseEdit), {merge: true});
    }

    cancelCaseRegistry() {
        this.childCase = null;
        this.currentStep = 1;
        this.registryProgress = 25;
        this.availableWizard = this.wizard;
        this.availableWizard.forEach(step => step.active = step.step === this.currentStep);
    }

    public nextStep() {
        if (this.registryProgress < 100 && !this.availableWizard[this.currentStep].active) {
            this.registryProgress += (this.availableWizard.length === this.wizard.length) ?
                ChildCaseService.STEP_PROGRESS : 33;
        }

        if (this.currentStep < this.availableWizard.length) {
            this.currentStep++;
        }

        if (this.registryProgress === 99) {
            this.registryProgress = 100;
        }

        this.availableWizard.forEach(step => {
            step.active = step.progress === this.registryProgress;
        });

        window.scrollTo({top: 0});
    }

    public previousStep() {
        if (this.registryProgress > 33 && !this.availableWizard[this.currentStep]?.active) {
            this.registryProgress -= (this.availableWizard.length === this.wizard.length) ?
                ChildCaseService.STEP_PROGRESS : 33;
        }

        if (this.registryProgress === 67) {
            this.registryProgress = 66;
        }

        if (this.currentStep > ChildCaseService.FIRST_STEP) {
            this.currentStep--;
        }

        this.availableWizard.forEach(step => {
            step.active = step.progress === this.registryProgress;
        });

        window.scrollTo({top: 0});
    }

    findForUnapSupervisor() {
        const organization = this.user.organizationName || 'invalid';
        return this.db.collection<ChildCaseModel>('/cases/', ref =>
            ref.where('organizationName', '==', organization)
                .orderBy('nextVisitDate', 'asc')).valueChanges();
    }


    public indexAllCases() {
        this.algoliaService.indexWithDate();
    }

    // Filter by next visit

    public getNextSevenDaysCases() {
        const inSevenDays = DateUtil.addDays(new Date(), 7);
        return this.index.search('', {
            filters: `nextVisitTimestamp >= ${DateUtil.nowTimestamp()} AND nextVisitTimestamp <= ${inSevenDays.getTime()}`
        });
    }

}
