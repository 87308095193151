import {Component, Input, OnInit} from '@angular/core';
import { PictureService } from '../../services/picture.service';
import {User} from '../../model/user.model';

@Component({
    selector: 'assisted-by',
    templateUrl: './assisted-by.component.html',
    styleUrls: ['./assisted-by.component.scss']
})
export class AssistedByComponent implements OnInit {

    @Input()
    public healthAssistant: User;

    constructor() {
    }

    ngOnInit() {
    }

    getSelfieurtl(){
        return PictureService.getPhotoUrl(this.healthAssistant.selfie);
    }    

}
