export class WomanQuiz {

    question1 = {
        id: 'question1',
        text: 'question1.info',
        options: ['question1.answer1', 'question1.answer2', 'question1.answer3'],
        correct: 'question1.answer2',
        answer: undefined,
        calc() {
            return this.answer === this.correct ? 1 : 0;
        }
    };

    question2 = {
        id: 'question2',
        text: 'question2.info',
        options: ['question2.answer1', 'question2.answer2', 'question2.answer3', 'question2.answer4', 'question2.answer5'],
        correct: 'question2.answer2',
        answer: undefined,
        calc() {
            return this.answer === this.correct ? 1 : 0;
        }
    };

    question3 = {
        id: 'question3',
        text: 'question3.info',
        options: ['question3.answer1', 'question3.answer2', 'question3.answer3', 'question3.answer4', 'question3.answer5'],
        correct: 'question3.answer3',
        answer: undefined,
        calc() {
            return this.answer === this.correct ? 1 : 0;
        }
    };

    question4 = {
        id: 'question4',
        text: 'question4.info',
        options: ['question4.answer1', 'question4.answer2', 'question4.answer3', 'question4.answer4'],
        correct: 'question4.answer3',
        answer: undefined,
        calc() {
            return this.answer === this.correct ? 1 : 0;
        }
    };

    question5 = {
        id: 'question5',
        text: 'question5.info',
        options: ['question5.answer1', 'question5.answer2', 'question5.answer3'],
        correct: 'question5.answer1',
        answer: undefined,
        calc() {
            return this.answer === this.correct ? 1 : 0;
        }
    };

    question6 = {
        id: 'question6',
        text: 'question6.info',
        options: ['question6.answer1', 'question6.answer2', 'question6.answer3'],
        correct: 'question6.answer2',
        answer: undefined,
        calc() {
            return this.answer === this.correct ? 1 : 0;
        }
    };

    question7 = {
        id: 'question7',
        text: 'question7.info',
        options: ['question7.answer1', 'question7.answer2', 'question7.answer3', 'question7.answer4', 'question7.answer5', 'question7.answer6'],
        correct: 'question7.answer6',
        answer: undefined,
        calc() {
            return this.answer === this.correct ? 1 : 0;
        }
    };

    values() {
        return [this.question1, this.question2, this.question3, this.question4, this.question5, this.question6, this.question7];
    }


}
