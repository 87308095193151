import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import algoliasearch from 'algoliasearch';
import {UserService} from './user.service';
import {User} from '../model/user.model';
import {ProvinceModel} from '../model/province.model';
import {ProvinceService} from './province.service';
import {CountyService} from './county.service';
import {CountyModel} from '../model/county.model';
import {AlgoliaUtil} from '../util/algolia.util';
import {Searchable} from '../pages/children/children.component';

@Injectable({
    providedIn: 'root'
})
export class FilterChildService {

    private user: User;

    organizationsUniqueIndex;
    indexByVisitDate;
    onlyMalnutritionCases = false;

    public searchable: Searchable;

    public set setSearchable(searchable: Searchable) {
        this.searchable = searchable;
    };

    constructor(public firestore: AngularFirestore,
                public userService: UserService,
                private provinceService: ProvinceService,
                private countyService: CountyService) {
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getSearchApiKey());
        this.organizationsUniqueIndex = client.initIndex(AlgoliaUtil.getOrganizationUniqueIndex());
        this.indexByVisitDate = client.initIndex(AlgoliaUtil.getChildrenIndex());
    }

    private provinces = new Array<ProvinceModel>();
    provinceCount = {};
    provinceSearchTerm = '';
    searchingProvince = true;
    provincesSelected = [];
    provincesIdsSelected = [];

    searchProvince() {
        this.searchingProvince = true;
        this.provinceService.listByProfile().then(async prov => {
            this.provinces = this.provinceSearchTerm ? prov.filter(item => item.name.toLowerCase().includes(this.provinceSearchTerm.toLowerCase())) : prov;
            let facetFilters = this.searchable.getFilters();
            await this.indexByVisitDate.search('', {
                facetFilters: facetFilters,
                facets: ['province']
            }).then(data2 => {
                this.provinceCount = data2.facets.province;
            });
            this.searchingProvince = false;
        });
    }

    clearProvinceSearch() {
        this.provinceSearchTerm = '';
        this.searchProvince();
    }

    toogleProvince(province) {
        if (this.provincesSelected.includes(province.name)) {
            this.provincesSelected = this.provincesSelected.filter(item => item !== province.name);
            this.provincesIdsSelected = this.provincesIdsSelected.filter(item => item !== province.id);
        } else {
            this.provincesSelected.push(province.name);
            this.provincesIdsSelected.push(province.id);
        }
        this.organizationSelected = [];
    }

    cleanProvinceFilters() {
        this.provincesSelected = [];
        this.provincesIdsSelected = [];
        this.countysSelected = [];
        this.searchable.search(true);
    }

    provinceFilter() {
        this.countysSelected = [];
        this.searchable.search(true);
    }

    //Countys
    private counties = new Array<CountyModel>();
    countiesCount = {};
    countySearchTerm = '';
    searchingCounty = true;
    countysSelected = [];

    async searchCounty() {
        this.searchingCounty = true;
        if (this.provincesIdsSelected.length > 0) {
            this.counties = this.countyService.listByProvinces(this.provincesIdsSelected);
        } else {
            this.counties = this.countyService.listAll;
        }
        this.counties = this.counties.sort((a, b) => a.name.localeCompare(b.name));
        if (this.countySearchTerm) {
            this.counties = this.counties.filter(item => item.name.toLowerCase().includes(this.countySearchTerm.toLowerCase()));
        }

        let facetFilters = this.searchable.getFilters();
        await this.indexByVisitDate.search('', {
            facetFilters: facetFilters,
            facets: ['county']
        }).then(data2 => {
            this.countiesCount = data2.facets.county;
        });

        this.searchingCounty = false;
    }

    clearCountySearch() {
        this.countySearchTerm = '';
        this.searchCounty();
    }

    toogleCounty(county) {
        if (this.countysSelected.includes(county.name)) {
            this.countysSelected = this.countysSelected.filter(item => item !== county.name);
        } else {
            this.countysSelected.push(county.name);
        }
        this.organizationSelected = [];
    }

    cleanCountyFilters() {
        this.countysSelected = [];
        this.searchable.search(true);
    }

    //Organizations
    private organizations = [];
    organizationsCount = {};
    organizationSearchTerm = '';
    searchingOrganization = true;
    organizationSelected = [];

    async searchOrganization() {
        this.searchingOrganization = true;
        const provinces = this.provincesSelected.map(p => (`province:${p}`));
        const counties = this.countysSelected.map(c => (`county:${c}`));

        console.log(provinces, counties);
        this.organizationsUniqueIndex.search(this.organizationSearchTerm, {
            facetFilters: [provinces, counties],
            hitsPerPage: 1000,
            attributesToRetrieve: ['*']
        }).then(async data => {
            this.searchingOrganization = false;
            if (data.nbHits > 0) {
                this.organizations = data.hits;
                this.organizations = this.organizations.filter((v, i, a) => a.findIndex(v2 => (v2.organizationName === v.organizationName)) === i);
                let facetFilters = this.searchable.getFilters();
                await this.indexByVisitDate.search('', {
                    facetFilters: facetFilters,
                    facets: ['organizationName']
                }).then(data2 => {
                    this.organizationsCount = data2.facets.organizationName;
                });
            } else {
                this.organizations = [];
            }
        });
    }

    clearOrganizationSearch() {
        this.organizationSearchTerm = '';
        this.searchOrganization();
    }

    toogleOrganization(organization) {
        if (this.organizationSelected.includes(organization['organizationName'])) {
            this.organizationSelected = this.organizationSelected.filter(item => item !== organization['organizationName']);
        } else {
            this.organizationSelected.push(organization['organizationName']);
        }
    }

    cleanOrganizationFilters() {
        this.organizationSelected = [];
        this.searchable.search(true);
    }

    //filters
    filterOptions = [
        {
            id: 0,
            icon: 'hospital-symbol',
            text: 'Niño referido al hospital',
            field: 'icon.icon',
            class: 'hospital-icon'
        },
        {
            id: 1,
            icon: 'calendar-times',
            text: 'Tratamiento interrumpido',
            field: 'icon.icon',
            class: 'calendar-icon'
        },
        {
            id: 2,
            icon: 'exclamation-triangle',
            text: 'Sin progreso',
            field: 'icon.icon',
            class: 'exclamation-icon'
        },
        {
            id: 3,
            icon: 'share-square',
            text: 'Caso transferido',
            field: 'icon.icon',
            class: 'share-square-icon'
        },
        {
            id: 4,
            icon: 'healed',
            text: 'Niño recuperado',
            field: 'marker',
            class: 'healed'
        },
        {
            id: 5,
            icon: 'user-circle',
            text: 'Desnutrición aguda severa',
            field: 'marker',
            class: 'severe-acute-malnutrition'
        },
        {
            id: 6,
            icon: 'user-circle',
            text: 'Desnutrición aguda moderada',
            field: 'marker',
            class: 'moderate-acute-malnutrition'
        },
        {
            id: 7,
            icon: 'user-circle',
            text: 'Riesgo de desnutrición aguda',
            field: 'marker',
            class: 'risk-of-acute-malnutrition'
        },
        {
            id: 8,
            icon: 'user-circle',
            text: 'Niño fuera de la zona de riesgo',
            field: 'marker',
            class: 'outsite-risk-zone'
        }
    ];

    filterCounts = {};
    filtersSelected = [];

    async searchFilterCounts() {
        let facetFilters = this.searchable.getFilters();
        await this.indexByVisitDate.search('', {
            facetFilters: facetFilters,
            facets: ['transferredCase', 'alert.class', 'marker']
        }).then(data2 => {
            let transferred = data2.facets['transferredCase'];
            this.filterCounts = {
                ...{'share-square-icon': transferred ? transferred['true'] : 0},
                ...data2.facets['marker'],
                ...data2.facets['alert.class']
            };
        });
    }

    clearFilter() {
        this.filtersSelected = [];
        this.searchable.search(true);
    }

    toogleFilter(filter) {
        if (this.filtersSelected.includes(filter.id)) {
            this.filtersSelected = this.filtersSelected.filter(item => item !== filter.id);
        } else {
            this.filtersSelected.push(filter.id);
        }
    }

}
