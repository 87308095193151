import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PictureChangingComponent} from './picture-changing.component';
import {ModalModule} from '../modal/modal.module';
import {FormsModule} from '@angular/forms';
import {CameraModalModule} from '../camera-modal/camera-modal.module';

@NgModule({
    declarations: [PictureChangingComponent],
    imports: [
        CommonModule,
        ModalModule,
        CameraModalModule,
        FormsModule
    ], exports: [PictureChangingComponent]
})
export class PictureChangingModule {
}
