import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ModalModel} from '../../model/modal.model';
import {WebcamImage} from 'ngx-webcam';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PictureService} from '../../services/picture.service';

@Component({
    selector: 'camera-modal',
    templateUrl: './camera-modal.component.html',
    styleUrls: ['./camera-modal.component.scss']
})
export class CameraModalComponent implements OnInit {

    static CAMERA: string = 'CAMERA';
    static LIBRARY: string = 'LIBRARY';

    @Input()
    modalConfig: ModalModel;
    @Input()
    type: string;
    @Output()
    pictureOutput = new EventEmitter<string>();
    closeResult = '';
    webcamImage: WebcamImage = null;
    cameraOn = false;
    showTakenPicture = false;
    selfie: string;
    takenType: string;
    @Input()
    message: string;
    @Input()
    title: string;
    @Input()
    imgUrl: string;

    constructor(private modalService: NgbModal) {
    }

    handleImage(webcamImage: WebcamImage) {
        this.webcamImage = webcamImage;
        this.pictureOutput.emit(webcamImage.imageAsDataUrl);
        this.showTakenPicture = true;
    }

    turnCameraOn() {
        this.cameraOn = true;
        this.showTakenPicture = false;
        this.setTakenType(CameraModalComponent.CAMERA);
    }

    savePicture(modal: any) {
        console.log('Salvando imagem...');
        modal.close();
    }

    close(modal: any) {
        this.initState();
        modal.close();
    }

    open(content) {
        this.modalService.open(content, {
            ariaLabelledBy: 'camera-modal-component',
            centered: true,
        }).result.then((closeAction) => {
            if (closeAction) {
                closeAction();
            }
        });
    }

    onSelectFile(event) {
        const file: Blob = event.target.files[0];

        if (event.target.files && file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (loadedFile) => {
                this.selfie = loadedFile.target.result as string;
                this.showTakenPicture = true;
                this.setTakenType(CameraModalComponent.LIBRARY);
            };
        }
    }

    takeAnotherPicture() {
        this.cameraOn = false;
        this.showTakenPicture = false;
        this.selfie = null;
        this.takenType = null;
    }

    setTakenType(type: string) {
        this.takenType = type;
    }

    confirm(modal) {
        if (!this.takenType) {
            return;
        }

        if (this.takenType === CameraModalComponent.CAMERA) {
            this.pictureOutput.emit(this.webcamImage.imageAsDataUrl);
        } else {
            this.pictureOutput.emit(this.selfie);
        }

        this.initState();

        modal.close();
    }

    initState() {
        this.cameraOn = false;
        this.showTakenPicture = false;
        this.selfie = null;
    }

    ngOnInit(): void {
    }

}
