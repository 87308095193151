export class ModalOptions {

    public header: string;
    public headerStyle?: string;
    public subHeader?: string;
    public message: string;
    public word?: string;

    constructor(object?: any) {
        if (object) {
            this.header = object.header;
            this.subHeader = object.subHeader;
            this.message = object.message;
            this.cancelButton = object.cancelButton;
            this.confirmButton = object.confirmButton;
            this.word = object?.word;
        }
    }

    public cancelButton?: {
        text: string;
        action: any;
        backgroundColor?: string;
    };

    public confirmButton: {
        text: string;
        action: any;
        backgroundColor?: string;
    };

}
