import {Component, Input, OnInit} from '@angular/core';
import {ChildQuiz} from './child-quiz';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EatingHabitModel} from '../../model/eating-habit.model';
import {EatingHabitsService} from '../../services/eating-habits.service';
import {WomanQuiz} from './woman-quiz';

@Component({
    selector: 'eating-habits',
    templateUrl: './eating-habits.component.html',
    styleUrls: ['./eating-habits.component.scss']
})
export class EatingHabitsComponent implements OnInit {

    public quiz = new ChildQuiz().values();
    public womanQuiz = new WomanQuiz().values();
    public eatingHabits: EatingHabitModel;
    @Input()
    public type: string;
    private action: string;

    constructor(public activeModal: NgbActiveModal,
                private eatingHabitsService: EatingHabitsService) {
    }

    public cancel() {
        this.action = 'CANCEL';
        this.close();
    }

    public close() {
        return this.activeModal.close();
    }

    isChildQuestions() {
        return this.type && this.type.toUpperCase() === 'CHILD';
    }

    isWomanQuestions() {
        return this.type && this.type.toUpperCase() === 'WOMAN';
    }

    available() {
        if (this.isWomanQuestions()) {
            return this.womanQuiz.map(quiz => quiz).filter(question => !question.answer).length === 0;
        } else {
            return this.quiz.map(quiz => quiz).filter(question => !question.answer).length === 0;
        }
    }

    public get isCancelled() {
        return this.action === 'CANCEL';
    }

    confirm() {
        if (this.isChildQuestions()) {
            this.eatingHabitsService.evaluateChild(this.quiz);
        } else {
            this.eatingHabitsService.evaluateWoman(this.womanQuiz);
        }
        this.action = 'SAVE';
        return this.close();
    }

    ngOnInit(): void {
    }

}
