import {ChildCaseModel} from './child-case.model';

export class ChildPersonalInfo {

    public token: string;
    public gender: string;

    constructor(children?: ChildCaseModel) {
        this.token = children.token;
        this.gender = children.gender;
    }

}
