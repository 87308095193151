import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterComponent} from './filter.component';
import {IconModule} from '../icon/icon.module';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [FilterComponent],
    imports: [
        CommonModule,
        IconModule,
        FormsModule
    ], exports: [FilterComponent]
})
export class FilterModule {
}
