import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'selfie',
    templateUrl: './selfie.component.html',
    styleUrls: ['./selfie.component.scss']
})
export class SelfieComponent implements OnInit {

    static AVATAR: string = '/assets/img/icons/no_profile_picture.svg';

    @Input()
    public picture: string = SelfieComponent.AVATAR;
    @Input()
    public class: string;

    constructor() {
    }

    ngOnInit(): void {
        if (!this.picture) {
            this.picture = SelfieComponent.AVATAR;
        }
    }

}
