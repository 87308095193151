import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EvaluationModel6} from '../../model/evaluation.model6';

@Component({
  selector: 'evaluation-list6',
  templateUrl: './evaluation-list6.component.html',
  styleUrls: ['./evaluation-list6.component.scss']
})
export class EvaluationList6Component implements OnInit {

  @Input()
  public values: Array<EvaluationModel6>;
  @Input()
  public label1: string;
  @Input()
  public label2: string;
  @Input()
  public label3: string;
  @Input()
  public label4: string;
  @Input()
  public label5: string;
  @Input()
  public label6: string;
  @Input()
  public title: string;

  searchBy: string;

  @ViewChild('filteredList')
  filteredList: Array<EvaluationModel6>;

  constructor(private modalService: NgbModal) {
  }

  open(content) {
      this.modalService.open(content, {
          ariaLabelledBy: 'values',
          animation: true,
          size: 'xl',
          centered: true,
      }).result.then((closeAction) => {
          if (closeAction) {
              closeAction();
          }
      });
  }

  close(modal) {
      modal.close();
  }

  ngOnInit(): void {
  }


}

