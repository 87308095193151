import { Injectable } from '@angular/core';
import { CountyModel } from '../model/county.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { ProvinceModel } from '../model/province.model';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class CountyService {

    private data = new Array<CountyModel>();

    constructor(private db: AngularFirestore) {
        this.loadData();
    }

    get listAll() {
        return this.data;
    }

    getByName(name: string): CountyModel {
        return this.data
            .filter(county => county.name == name)[0];
    }

    listByProvince(province: ProvinceModel) {
        if (!province || !province.id) {
            return;
        }
        return this.data
            .filter(county => county.province == province.id)
            .sort(this.compare);
    }

    listByProvinces(provincesIds: Array<number>) {
        if (!provincesIds || provincesIds.length == 0) {
            return;
        }
        return this.data
            .filter(county => provincesIds.includes(county.province))
            .sort(this.compare);
    }

    loadCounties(province: ProvinceModel) {
        return this.db.collection<CountyModel>('counties', ref => ref.where('province', '==', province.id))
            .valueChanges();
    }

    private compare(county1, county2) {
        let comparison = 0;
        if (county1.name > county2.name) {
            comparison = 1;
        } else if (county1.name < county2.name) {
            comparison = -1;
        }
        return comparison;
    }

    public loadData() {
        this.db.firestore.collection('counties')
            .onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const newData = change.doc.data() as CountyModel;
                    const index = _.findIndex(this.data, (obj) => _.isEqual(obj, newData));
                    if (index === -1) {
                        this.data.push(newData);
                    }
                });
            });
    }

}
