import {Component, Input, OnInit} from '@angular/core';
import {ModalOptions} from '../../options/modal.options';
import {LoadingService} from '../../services/loading.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  @Input()
  public options: ModalOptions;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
