import {Injectable} from '@angular/core';
import {WomanCaseModel} from '../model/woman-case.model';
import {WomanCaseType} from '../constants/woman-case-type';
import {Observable} from 'rxjs/Observable';
import {PictureService} from './picture.service';
import {LoginService} from './login.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ParseObjectUtil} from '../util/parse-object.util';
import {SortUtil} from '../util/sort.util';
import {FilterUtil} from '../util/filter.util';
import {ChildCaseModel} from '../model/child-case.model';
import * as _ from 'lodash';
import {DateUtil} from '../util/date.util';
import algoliasearch, {SearchIndex} from 'algoliasearch';
import {AlgoliaUtil} from '../util/algolia.util';

@Injectable({
    providedIn: 'root'
})
export class WomanCaseService {

    static STEP_PROGRESS: number = 25;
    static LAST_STEP: number = 4;
    static FIRST_STEP: number = 1;
    public womanCase: WomanCaseModel;
    public womanCaseEdit: WomanCaseModel;
    public womanCaseDelegate: WomanCaseModel;
    public womanCaseSnapshot: WomanCaseModel;
    public registryProgress: number = 25;
    public currentStep: number = 1;
    public availableWizard: any[];
    public gestationWeeks: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42];
    private index: SearchIndex;

    public wizard = [
        {
            step: 1,
            active: this.registryProgress <= 25,
            progress: 25,
            title: '1. Datos de la visita'
        },
        {
            step: 2,
            active: this.registryProgress <= 50 && this.registryProgress > 25,
            progress: 50,
            title: '2. Datos de registro'
        },
        {
            step: 3,
            active: this.registryProgress <= 75 && this.registryProgress > 50,
            progress: 75,
            title: '3. Sintomas'
        },
        {
            step: 4,
            active: this.registryProgress <= 100 && this.registryProgress > 75,
            progress: 100,
            title: '4. Resultados'
        }
    ];

    public newVisitWizard = [
        {
            step: 1,
            active: this.registryProgress <= 33,
            progress: 33,
            title: '1. Datos de la visita'
        },
        {
            step: 2,
            active: this.registryProgress <= 66 && this.registryProgress > 33,
            progress: 66,
            title: '2. Sintomas'
        },
        {
            step: 3,
            active: this.registryProgress <= 100 && this.registryProgress > 66,
            progress: 100,
            title: '3. Resultados'
        }
    ];

    constructor(private pictureService: PictureService,
                private loginService: LoginService,
                private db: AngularFirestore) {

        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getWomenAPIKey());
        this.index = client.initIndex(AlgoliaUtil.getWomenIndex());
    }

    newVisit(womanCase: WomanCaseModel) {
        this.womanCaseSnapshot = _.cloneDeep(womanCase);
        this.womanCase = womanCase;
        this.womanCase.muac = null;
        this.womanCase.visitDate = null;
        this.womanCase.type = null;
        this.womanCase.comments = null;

        this.registryProgress = 33;
        this.availableWizard = _.cloneDeep(this.newVisitWizard);
        this.currentStep = 1;
    }

    public availableGestationWeeks() {
        if (!this.womanCase.token || this.womanCase.type === WomanCaseType.LACTATING.i18nkey || this.womanCase.gestationWeek == null) {
            return this.gestationWeeks;
        }

        const visits = this.womanCase.visits.filter(visit => visit.marker != 'no-measured');
        if (!visits) {
            return this.gestationWeeks;
        }
        const LAST_VISIT = visits.length - 1;
        return this.gestationWeeks.slice(visits[LAST_VISIT]?.gestationWeek, this.gestationWeeks.length);
    }

    async edit() {
        if (this.womanCaseEdit.selfieUrl && this.womanCaseEdit.selfieUrl.includes('data')) {
            await fetch(this.womanCaseEdit.selfieUrl).then(async response => {
                this.womanCaseEdit.selfie = await this.pictureService.upload(await response.blob());
                this.womanCaseEdit.selfieUrl = await this.pictureService.getPictureURL(this.womanCaseEdit.selfie) as string;

                return this.db.collection<WomanCaseModel>('womanCases').doc(this.womanCaseEdit.token)
                    .set(ParseObjectUtil.parse(this.womanCaseEdit), {merge: true});
            });
        }
        return this.db.collection<WomanCaseModel>('womanCases').doc(this.womanCaseEdit.token)
            .set(ParseObjectUtil.parse(this.womanCaseEdit), {merge: true});
    }

    initCase() {
        if (!this.womanCase) {
            console.log('Iniciando um novo caso');
            this.womanCase = new WomanCaseModel(this.loginService.currentUser);
            this.availableWizard = this.wizard;
        }
    }

    findMyCases() {
        return this.db.collection<WomanCaseModel>('womanCases',
            ref => ref.where('healthAssistantsUids', 'array-contains', this.loginService.authUser?.uid)
                .orderBy('nextVisitDate', 'asc')).valueChanges();
    }

    find(sortBy?: SortUtil, filterBy?: FilterUtil) {

        if (!sortBy || !sortBy.field) {
            sortBy = new SortUtil('nextVisitDate', 'asc');
        }

        if (this.loginService.authUser.srs) {
            return this.findForSRS(sortBy, filterBy);
        } else if (this.loginService.authUser.organizationType?.includes('UNICEF')) {
            return this.findAll(sortBy, filterBy);
        } else if (this.loginService.authUser.dps) {
            return this.findForDPS(sortBy, filterBy);
        } else {
            return this.findOrganizationCases(sortBy, filterBy);
        }
    }

    // List by organization
    findOrganizationCases(sortBy: SortUtil, filterBy?: FilterUtil): Observable<WomanCaseModel[]> {
        if (filterBy) {
            return this.db.collection<WomanCaseModel>('womanCases',
                ref => ref.where('organizationName', '==', this.loginService.authUser?.organizationName)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)).valueChanges();
        }

        return this.db.collection<WomanCaseModel>('womanCases',
            ref => ref.where('organizationName', '==', this.loginService.authUser?.organizationName)
                .orderBy(sortBy.field, sortBy.direction)).valueChanges();
    }

    // List for national
    findAll(sortBy: SortUtil, filterBy?: FilterUtil) {
        if (filterBy) {
            return this.db.collection<WomanCaseModel>('womanCases',
                ref => ref.where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)).valueChanges();
        }

        return this.db.collection<WomanCaseModel>('womanCases',
            ref => ref.orderBy(sortBy.field, sortBy.direction)).valueChanges();
    }

    // List for SRS
    findForSRS(sortBy: SortUtil, filterBy?: FilterUtil) {
        const provinces = this.loginService.provinces.map(province => province.name);
        if (filterBy) {
            return this.db.collection<WomanCaseModel>('womanCases',
                ref => ref.where(filterBy.field, filterBy.option, filterBy.value)
                    .where('province', 'in', provinces)
                    .orderBy(sortBy.field, sortBy.direction)).valueChanges();
        }

        return this.db.collection<WomanCaseModel>('womanCases',
            ref => ref.where('province', 'in', provinces).orderBy(sortBy.field, sortBy.direction)).valueChanges();
    }

    // List for SRS
    findForDPS(sortBy: SortUtil, filterBy?: FilterUtil) {
        const province = this.loginService.authUser.dps.provinceReference;
        if (filterBy) {
            return this.db.collection<WomanCaseModel>('womanCases',
                ref => ref.where('province', '==', province)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)).valueChanges();
        }
        return this.db.collection<WomanCaseModel>('womanCases',
            ref => ref.where('province', '==', province).orderBy(sortBy.field, sortBy.direction)).valueChanges();
    }


    cancelCaseRegistry() {
        this.womanCase = null;
        this.currentStep = 1;
        this.registryProgress = 25;
        this.availableWizard = this.wizard;
        this.availableWizard.forEach(step => step.active = step.step === this.currentStep);
    }

    isLactating(womanCase?: WomanCaseModel) {
        if (womanCase) {
            return womanCase.type === WomanCaseType.LACTATING.i18nkey;
        }
        return this.womanCase.type === WomanCaseType.LACTATING.i18nkey;
    }

    isPregnant(womanCase?: WomanCaseModel) {
        if (womanCase) {
            return womanCase.type === WomanCaseType.PREGNANT.i18nkey;
        }
        return this.womanCase.type === WomanCaseType.PREGNANT.i18nkey;
    }

    cleanCaseSpaces() {
        const propsCaseToClean = [
            'address',
            'chronicDisease',
            'firstName',
            'lastName',
        ];

        propsCaseToClean.forEach(props => {
            if (this.womanCase[props]) {
                this.womanCase[props] = this.womanCase[props].trim();
                this.womanCase[props] = this.womanCase[props].replace(/\s\s+/g, ' ');
            }
        });
    }

    async save(): Promise<void> {
        this.womanCase.updateDate = new Date();
        this.womanCase.appVersion = 'WEB';
        for (let i = 0; i < this.womanCase.visits.length; i++) {
            if (this.womanCase.visits[i].marker != 'no-measured' && !this.womanCase.visits[i].date) {
                this.womanCase.visits[i].date = this.womanCase.visitDate;
            }
        }

        this.cleanCaseSpaces();

        if (this.womanCase.visits.filter(visit => visit.date).length > 1) {
            return this.update();
        } else {
            if (this.womanCase.selfieUrl && this.womanCase.selfieUrl.includes('data')) {
                await fetch(this.womanCase.selfieUrl).then(async response => {
                    this.womanCase.selfie = await this.pictureService.upload(await response.blob());
                    this.womanCase.selfieUrl = await this.pictureService.getPictureURL(this.womanCase.selfie) as string;
                    return this.caseRegistry();
                });
            } else {
                this.womanCase.selfieUrl = await this.pictureService.getPictureURL(PictureService.NO_PROFILE_PICTURE) as string;
                return this.caseRegistry();
            }
        }

    }

    update(womanCase?: WomanCaseModel) {
        if (womanCase) {
            return this.db.collection<WomanCaseModel>('womanCases')
                .doc(womanCase.token)
                .set(ParseObjectUtil.parse(womanCase), {merge: true});
        }
        return this.db.collection<WomanCaseModel>('womanCases')
            .doc(this.womanCase.token)
            .set(ParseObjectUtil.parse(this.womanCase), {merge: true});
    }

    caseRegistry() {
        return this.db.collection('womanCases').doc(this.womanCase.token).set(ParseObjectUtil.parse(this.womanCase));
    }

    public nextStep() {
        if (this.registryProgress < 100 && !this.availableWizard[this.currentStep].active) {
            this.registryProgress += (this.availableWizard.length === this.wizard.length) ?
                WomanCaseService.STEP_PROGRESS : 33;
        }

        if (this.currentStep < this.availableWizard.length) {
            this.currentStep++;
        }

        if (this.registryProgress === 99) {
            this.registryProgress = 100;
        }

        this.availableWizard.forEach(step => {
            step.active = step.progress === this.registryProgress;
        });

        window.scrollTo({top: 0});
    }

    public previousStep() {
        if (this.registryProgress > 33 && !this.availableWizard[this.currentStep]?.active) {
            this.registryProgress -= (this.availableWizard.length === this.wizard.length) ?
                WomanCaseService.STEP_PROGRESS : 33;
        }

        if (this.registryProgress === 67) {
            this.registryProgress = 66;
        }

        if (this.currentStep > WomanCaseService.FIRST_STEP) {
            this.currentStep--;
        }

        this.availableWizard.forEach(step => {
            step.active = step.progress === this.registryProgress;
        });

        window.scrollTo({top: 0});
    }

    public getNextSevenDaysCases() {
        const inSevenDays = DateUtil.addDays(new Date(), 7);
        return this.index.search('', {
            filters: `nextVisitTimestamp >= ${DateUtil.nowTimestamp()} AND nextVisitTimestamp <= ${inSevenDays.getTime()}`
        });
    }
}
