export class NextVisitPeriod {
// {
//     key: 'NEXT_SEVEN_DAYS',
//     label: 'Próximos 7 dias',
//     filter() {
//
//     }
// },
// {
//     key: 'NEXT_THIRTY_DAYS',
//         label: 'Próximos 30 dias',
//     filter() {
//
// }
// },
// {
//     key: 'LAST_SEVEN_DAYS',
//         label: 'Últimos 7 dias',
//     filter() {
//
// }
// },

    public static NEXT_SEVEN_DAYS = {
        key: 'NEXT_SEVEN_DAYS',
        label: 'Próximos 7 días'
    };

    public static NEXT_THIRTY_DAYS = {
        key: 'NEXT_THIRTY_DAYS',
        label: 'Próximos 30 días'
    };

    public static LAST_SEVEN_DAYS = {
        key: 'LAST_SEVEN_DAYS',
        label: 'Últimos 7 días'
    };

    public static TODAY = {
        key: 'TODAY',
        label: 'Hoy'
    };

    public static CURRENT_MONTH = {
        key: 'CURRENT_MONTH',
        label: 'Mes actual'
    };

    public static CURRENT_YEAR = {
        key: 'CURRENT_YEAR',
        label: 'Año actual'
    };

    public static LAST_YEAR = {
        key: 'LAST_YEAR',
        label: 'Año pasado'
    };

    public static ALL = {
        key: 'ALL',
        label: 'Todo el periodo'
    };

    public static CUSTOM = {
        key: 'CUSTOM',
        label: 'Periodo personalizado'
    };


    static values() {
        return [
            NextVisitPeriod.NEXT_SEVEN_DAYS,
            NextVisitPeriod.NEXT_THIRTY_DAYS,
            NextVisitPeriod.LAST_SEVEN_DAYS,
            NextVisitPeriod.TODAY,
            NextVisitPeriod.CURRENT_MONTH,
            NextVisitPeriod.CURRENT_YEAR,
            NextVisitPeriod.LAST_YEAR,
            NextVisitPeriod.ALL,
            NextVisitPeriod.CUSTOM
        ];
    }

}
