import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalComponent} from './modal.component';
import {FormsModule} from '@angular/forms';
import {CameraModule} from '../camera/camera.module';
import {EatingHabitsModule} from '../eating-habits/eating-habits.module';


@NgModule({
    declarations: [ModalComponent],
    imports: [
        CommonModule,
        FormsModule,
        CameraModule,
        EatingHabitsModule,
    ], exports: [ModalComponent]
})
export class ModalModule {
}
