import {Component, Input, OnInit} from '@angular/core';
import {MuacUtil} from '../../util/muac.util';

@Component({
    selector: 'muac-box',
    templateUrl: './muac.component.html',
    styleUrls: ['./muac.component.scss']
})
export class MuacComponent implements OnInit {

    @Input()
    public muac: number;
    @Input()
    public caseType: string = 'child';

    constructor() {
    }

    public muacFormatter() {
        if (this.caseType === 'child') {
            return MuacUtil.getMuacClassification(this.muac);
        } else {
            return MuacUtil.getWomanMuacClassification(this.muac);
        }
    }

    ngOnInit() {
    }

}
