import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { WomanCaseModel } from '../model/woman-case.model';
import { WomanDashboardModel } from '../model/woman-dashboard.model';
import { SrsService } from './srs.service';
import { ProvinceService } from './province.service';
import { LoginService } from './login.service';
import firebase from 'firebase';
import { ParseObjectUtil } from '../util/parse-object.util';
import { ChildrenDashboardModel } from '../model/children-dashboard.model';
import { ChildCaseModel } from '../model/child-case.model';


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private itemsSubject: BehaviorSubject<string> = new BehaviorSubject("C");
    currentDashboard = this.itemsSubject.asObservable();

    constructor(private db: AngularFirestore,
        private loginService: LoginService,
        private provinceService: ProvinceService,
        private srsService: SrsService) {
    }

    changeCurrentDashboard(message: string) {
        this.itemsSubject.next(message)
    }

    updateWomanDashboard(): Promise<any> {
        return new Promise((resolve) => {
            const map = new Map<string, WomanDashboardModel>();
            map.set('NATIONAL', new WomanDashboardModel());
            this.db.firestore.collection('womanCases').get().then(values => {
                const cases = values.docs.map(x => x.data() as WomanCaseModel);
                console.log(cases.length + ' woman cases found');
                cases.map(womanCase => map.set(womanCase.organizationName, new WomanDashboardModel()));
                this.srsService.listAll().map(srs => map.set(srs.name, new WomanDashboardModel()));
                this.provinceService.listAll().map(province => map.set(province.name, new WomanDashboardModel()));
                for (let i = 0; i < cases.length; i++) {
                    const woman: WomanCaseModel = cases[i];
                    const srs = this.provinceService?.findSRS(woman.province);
                    map.get('NATIONAL')?.init(woman.type, woman);
                    map.get(woman.organizationName)?.init(woman.type, woman);
                    map.get(woman.province)?.init(woman.type, woman);
                    if (srs) {
                        map.get(srs.name)?.init(woman.type, woman);
                    }
                }

                const db = firebase.firestore();
                const batch = db.batch();
                let count = 0;
                map.forEach(async (value, key) => {
                    if (key && key.length > 0) {
                        const docRef = db.collection('statisticWoman').doc(key);
                        batch.update(docRef, ParseObjectUtil.parse(value));
                        count++;
                    }
                });
                batch.commit().then(() => {
                    console.log('Dashboard updated');
                    console.log(count + ' successfully updated');
                });

                resolve({
                    count: count,
                    cases: cases.length
                });
            });
        });
    }

    updateChildrenDashboard(): Promise<any> {

        return new Promise(resolve => {
            const map = new Map<string, ChildrenDashboardModel>();

            map.set('NATIONAL', new ChildrenDashboardModel());
            this.db.firestore.collection('cases').get().then(values => {
                const cases = values.docs.map(x => x.data() as ChildCaseModel);
                console.log(cases.length + ' child cases found');
                cases.map(childCase => map.set(childCase.organizationName, new ChildrenDashboardModel()));
                this.srsService.listAll().map(srs => map.set(srs.name, new ChildrenDashboardModel()));
                this.provinceService.listAll().map(province => map.set(province.name, new ChildrenDashboardModel()));

                for (let i = 0; i < cases.length; i++) {
                    const child: ChildCaseModel = cases[i];
                    const srs = this.provinceService?.findSRS(child.province);
                    map.get('NATIONAL')?.init('NATIONAL', child);
                    map.get(child.organizationName)?.init(child.organizationName, child);
                    map.get(child.province)?.init(child.province, child);
                    if (srs) {
                        map.get(srs.name)?.init(srs.name, child);
                    }
                }

                const db = firebase.firestore();
                const largeBatch = [];
                largeBatch.push(db.batch());
                if (map.size > 500) {
                    largeBatch.push(db.batch());
                }
                let count = 0;
                map.forEach(async (value, key) => {
                    if (key && key.length > 0) {
                        const docRef = db.collection('statisticChildren').doc(key);
                        if (count <= 499) {
                            largeBatch[0].set(docRef, ParseObjectUtil.parse(value));
                        } else {
                            largeBatch[1].set(docRef, ParseObjectUtil.parse(value));
                        }
                        count++;
                    }
                });

                largeBatch.forEach(async batch => {
                    await batch.commit().then(() => {
                        console.log('Dashboard updated');
                        console.log(count + ' successfully updated');
                    });
                });

                resolve({
                    count: count,
                    cases: cases.length
                });

            });
        });
    }
}
