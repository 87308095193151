import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'validation',
    templateUrl: './validation.component.html',
    styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {

    @Input()
    value: any;
    @Input()
    name: string;
    @Input()
    min: number;
    @Input()
    max: number;
    @Input()
    showIf: boolean;
    @Input()
    requiredMessage: string;
    @Input()
    minLengthMessage: string;
    @Input()
    maxLengthMessage: string;
    @Input()
    isEmail: boolean;

    constructor() {
    }

    validMail() {
        const emailValidate = String(this.value?.model || this.value).toLowerCase();
        const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const valid = regex.test(emailValidate);
        return valid;
    }

    ngOnInit() {
    }

}
