import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs/Observable';

@Component({
    selector: 'app-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {

    @Output()
    public pictureTaken = new EventEmitter<WebcamImage>();

    @Output()
    public cancel = new EventEmitter<void>();

    @Output()
    public action = new EventEmitter<void>();

    public webcamImage: WebcamImage;

    @Input()
    public showWebcam = false;
    public allowCameraSwitch = false;
    public multipleWebcamsAvailable = false;
    public deviceId: string;
    public errors: WebcamInitError[] = [];
    private trigger: Subject<void> = new Subject<void>();

    public ngOnInit(): void {
        WebcamUtil.getAvailableVideoInputs()
            .then((mediaDevices: MediaDeviceInfo[]) => {
                this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
            });
    }

    public triggerSnapshot(): void {
        this.trigger.closed = false;
        this.trigger.next();
    }

    public toggleWebcam(): void {
        this.showWebcam = !this.showWebcam;
    }

    public handleInitError(error: WebcamInitError): void {
        console.log('error', error);
        this.errors.push(error);
    }

    public handleImage(webcamImage: WebcamImage): void {
        console.log('received webcam image', webcamImage);
        this.webcamImage = webcamImage;
        // this.pictureTaken.emit(webcamImage);
    }

    public confirm() {
        this.pictureTaken.emit(this.webcamImage);
        this.trigger.next();
        this.action.emit();
    }

    public cancelAction() {
        this.showWebcam = false;
        this.webcamImage = null;
        this.cancel.emit();
    }

    public takeAnotherPicture() {
        this.showWebcam = true;
        this.webcamImage = null;
    }

    public cameraWasSwitched(deviceId: string): void {
        console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

}
