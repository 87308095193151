import {Injectable} from '@angular/core';
import {DateUtil} from './date.util';

@Injectable({
    providedIn: 'root'
})
export class WomanUtil {

    public getWeeksText(weeks: number): string {
        if (weeks > 0) {
            return 'numberOfWeeks';
        } else {
            return 'notInformed';
        }
    }

    getAge(date: Date) {
        return DateUtil.yearsDiff(date, new Date());
    }

}
