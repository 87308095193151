import {WomanCaseModel} from './woman-case.model';

export class WomanInfo {

    public token: string;
    public name: string;
    public muac: number;
    public province: string;
    public county: string;
    public organization: string;
    public healthAssistantUID: string;
    public marker: string;
    public alert: string;
    public hospitalCase: boolean;
    public type: string;
    public firstVisit: Date;

    constructor(womanCaseModel: WomanCaseModel) {
        this.token = womanCaseModel.token;
        this.name = womanCaseModel.firstName + ' ' + womanCaseModel.lastName;
        this.muac = womanCaseModel.muac;
        this.province = womanCaseModel.province;
        this.county = womanCaseModel.county;
        this.organization = womanCaseModel.organizationName;
        this.healthAssistantUID = womanCaseModel.healthAssistant?.uid;
        this.marker = womanCaseModel.marker;
        this.hospitalCase = womanCaseModel.womanReferredToTheHospital;
        this.type = womanCaseModel.type;
        this.alert = womanCaseModel.iconAlert?.icon;
        this.firstVisit = womanCaseModel.visitDate;
    }

}
