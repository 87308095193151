export class WomanCaseType {

    public static PREGNANT = {
        value: 'Pregnant',
        i18nkey: 'woman.pregnant'
    };

    public static LACTATING = {
        value: 'Lactating',
        i18nkey: 'woman.lactating'
    };

}
