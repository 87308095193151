import {ChildCaseModel} from './child-case.model';

export class ChildrenDashboardModel {

    healed: number;
    outsideRiskZone: number;
    riskOfAcuteMalnutrition: number;
    moderateAcuteMalnutrition: number;
    severeAcuteMalnutrition: number;
    hospitalized: number;
    interruptedTreatment: number;
    noProgress: number;
    noMeasured: number;
    transferred: number;

    public type: string;
    public total: number;
    private markers = ['no-measured',
        'healed',
        'outsite-risk-zone',
        'risk-of-acute-malnutrition',
        'moderate-acute-malnutrition',
        'severe-acute-malnutrition'];

    constructor(type?: string) {
        if (type) {
            this.type = type;
        }
        this.total = 0;
        this.noProgress = 0;
        this.transferred = 0;
        this.moderateAcuteMalnutrition = 0;
        this.interruptedTreatment = 0;
        this.outsideRiskZone = 0;
        this.riskOfAcuteMalnutrition = 0;
        this.severeAcuteMalnutrition = 0;
        this.noMeasured = 0;
        this.hospitalized = 0;
        this.healed = 0;
    }

    public sum(dashboard: ChildrenDashboardModel) {
        this.total = this.total + dashboard.total;
        this.noProgress = this.noProgress + dashboard.noMeasured;
        this.transferred = this.transferred + dashboard.transferred;
        this.moderateAcuteMalnutrition = this.moderateAcuteMalnutrition + dashboard.moderateAcuteMalnutrition;
        this.interruptedTreatment = this.interruptedTreatment + dashboard.interruptedTreatment;
        this.outsideRiskZone = this.outsideRiskZone + dashboard.outsideRiskZone;
        this.riskOfAcuteMalnutrition = this.riskOfAcuteMalnutrition + dashboard.riskOfAcuteMalnutrition;
        this.severeAcuteMalnutrition = this.severeAcuteMalnutrition + dashboard.severeAcuteMalnutrition;
        this.noMeasured = this.noMeasured + dashboard.noMeasured;
        this.hospitalized = this.hospitalized + dashboard.hospitalized;
        this.healed = this.healed + dashboard.healed;
    }

    public init(type: string, childCase: ChildCaseModel) {
        this.type = type;
        this.total++;
        const lastVisit = childCase.visits != null ? this.getLastVisit(childCase) : null;
        this.updateLevel(childCase.marker);

        if (lastVisit != null) {
            if (lastVisit.fowardToHospital) {
                this.hospitalized++;
            }

            if (lastVisit.alertIcon != null && lastVisit.alertIcon.icon == 'exclamation-triangle') {
                this.noProgress++;
            }
        }

        if (childCase.transferredCase || childCase.transferAssistant != null) {
            this.transferred++;
        }

        if (childCase.alert?.icon != null && childCase.alert?.icon == 'calendar-times') {
            this.interruptedTreatment++;
        }

    }

    public updateLevel(marker: string) {
        switch (marker) {
            case 'no-measured':
                this.noMeasured++;
                break;
            case 'healed':
                this.healed++;
                break;
            case 'outsite-risk-zone':
            case 'outside-risk-zone':
                this.outsideRiskZone++;
                break;
            case 'severe-acute-malnutrition':
                this.severeAcuteMalnutrition++;
                break;
            case 'risk-of-acute-malnutrition':
                this.riskOfAcuteMalnutrition++;
                break;
            case 'moderate-acute-malnutrition':
                this.moderateAcuteMalnutrition++;
                break;
        }
    }

    public downgradeLevel(marker: string) {
        console.log('downgrade de ', marker);
        switch (marker) {
            case 'no-measured':
                this.noMeasured--;
                break;
            case 'healed':
                this.healed--;
                break;
            case 'outsite-risk-zone':
            case 'outside-risk-zone':
                this.outsideRiskZone--;
                break;
            case 'severe-acute-malnutrition':
                this.severeAcuteMalnutrition--;
                break;
            case 'risk-of-acute-malnutrition':
                this.riskOfAcuteMalnutrition--;
                break;
            case 'moderate-acute-malnutrition':
                this.moderateAcuteMalnutrition--;
                break;
        }
    }

    private getLastVisit(childCase: ChildCaseModel) {
        const visits = childCase.visits.filter(visit => visit.date != null);
        if (visits == null || visits.length === 0) {
            return null;
        }
        return visits[visits.length - 1];
    }

    public updateThis(dashboard: ChildrenDashboardModel) {
        this.total = this.total + dashboard.total;
        this.hospitalized = this.hospitalized + dashboard.hospitalized;
        this.healed = this.healed + dashboard.healed;
        this.outsideRiskZone = this.outsideRiskZone + dashboard.outsideRiskZone;
        this.riskOfAcuteMalnutrition = this.riskOfAcuteMalnutrition + dashboard.riskOfAcuteMalnutrition;
        this.interruptedTreatment = this.interruptedTreatment + dashboard.interruptedTreatment;
        this.noProgress = this.noProgress + dashboard.noProgress;
        this.severeAcuteMalnutrition = this.severeAcuteMalnutrition + dashboard.severeAcuteMalnutrition;
        this.noMeasured = this.noMeasured + dashboard.noMeasured;
        this.transferred = this.transferred + dashboard.transferred;
        this.moderateAcuteMalnutrition = this.moderateAcuteMalnutrition + dashboard.moderateAcuteMalnutrition;
    }

}
