import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextListComponent} from './text-list.component';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
    declarations: [TextListComponent],
    imports: [
        CommonModule,
        TranslocoModule
    ],
    exports: [TextListComponent]
})
export class TextListModule {
}
