import {Routes} from '@angular/router';
import {ErrorComponent} from './pages/error/error.component';
import {AppGuard} from './app.guard';

export const ROUTES: Routes = [
    {
        path: '', redirectTo: 'login', pathMatch: 'full'
    },
    {
        path: 'app', canActivate: [AppGuard],
        // @ts-ignore
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
    },
    {
        path: 'login',
        // @ts-ignore
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'register',
        // @ts-ignore
        loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
    },
    {
        path: 'recover-password',
        // @ts-ignore
        loadChildren: () => import('./pages/recover-password/recover-password.module').then(m => m.RecoverPasswordModule)
    },
    {
        path: 'error', component: ErrorComponent
    },
    {
        path: '**', component: ErrorComponent
    }
];
