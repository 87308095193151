import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalModel} from '../../model/modal.model';
import {WebcamImage} from 'ngx-webcam';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
    @Input() modalConfig: ModalModel;
    closeResult = '';
    @Input()
    showButton = true;
    templateContent: any;
    @Input()
    type: string;
    public webcamImage: WebcamImage = null;
    @Input()
    actionDisabled: boolean = false;

    constructor(private modalService: NgbModal) {
    }

    handleImage(webcamImage: WebcamImage) {
        this.webcamImage = webcamImage;
    }

    open(content) {
        this.modalService.open(content, {
            ariaLabelledBy: 'app-modal-component',
            centered: true,
        }).result.then((closeAction) => {
            if (closeAction) {
                closeAction();
            }
        });
    }
}
