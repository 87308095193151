import { SrsModel } from './srs.model';
import { DpsModel } from './dps.model.';
import { ProvinceModel } from './province.model';
import { CountyModel } from './county.model';
import { UnapModel } from './unap.model';
import { OrganizationService } from '../services/organization.service';
import {InaipiModel} from './inaipi.model';
import {CruzRojaModel} from './cruz-roja.model';
import {InaipiCruzRojaMigration} from './inaipi-cruz-roja-migration.model.';
import {HospitalModel} from './hospital.model';

export class User {

    objectID: string;
    selfie: string;
    selfieRef: string;
    firstName: string;
    lastName: string;
    email: string;
    identityCard: string = '';
    organizationModel: UnapModel;
    inaipiOrganizationModel: InaipiModel;
    cruzRojaOrganizationModel: CruzRojaModel;
    hospitalOrganizationModel: HospitalModel;
    organizationName: string;
    organizationInitials: string;
    organizationType: string;
    phone: string = '';
    province: string;
    createdAt: number;
    createdAtDay: number;
    createdAtMonth: number;
    createdAtYear: number;
    emailVerified: boolean;
    provinceModel: ProvinceModel;
    county: string;
    countyModel: CountyModel;
    uid: string;
    serviceType: string;
    srs: SrsModel;
    dps: DpsModel;
    admin: boolean = false;
    active: boolean = true;
    deletedBy: string;
    inaipiCruzRojaMigration: InaipiCruzRojaMigration;

    public get name() {
        return this.firstName + ' ' + this.lastName;
    }

    public get isSupervisor(): boolean {
        return 'SUPERVISE' == this.serviceType || this.organizationInitials == 'Supervisores';
    }

    public get isOrgSupervisors(): boolean {
        return this.organizationInitials == 'Supervisores';
    }

    public get isAdmin(): boolean {
        return this.admin && this.isSupervisor;
    }

    public get isSupervisorNacional(): boolean {
        return ('SUPERVISE' == this.serviceType || this.organizationInitials == 'Supervisores')
            && OrganizationService.nationalOrganizations.includes(this.organizationType);
    }

}
