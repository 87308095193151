import firebase from 'firebase';
import OrderByDirection = firebase.firestore.OrderByDirection;

export class SortUtil {

    public field: string;
    public direction: OrderByDirection;

    constructor(field: string, direction: string) {
        this.field = field;
        this.direction = direction.includes('asc') ? 'asc' : 'desc';
    }

}
