import {ChildCaseModel} from './child-case.model';

export class ChildModel {

    token: string;
    selfie: string;
    selfieUrl: string;
    firstName: string;
    lastName: string;
    gender: string;
    birthDate: Date;
    birthCertificateNumber: string;
    motherName: string;
    motherPhone: string;
    address: string;
    nextVisit: string;
    updateDate: Date;
    province: string;
    county: string;
    chronicDisease: string;
    caregiverName: string;
    caregiverPhone: string;

    update() {
        this.updateDate = new Date();
    }

    // For old app versions
    updateWithCase(childCase: ChildCaseModel) {
        this.selfie = childCase.childSelfieRef;
        this.selfieUrl = childCase.childSelfie;
        this.motherName = childCase.mothersName;
        this.gender = childCase.gender;
        this.token = childCase.token;
        this.birthDate = childCase.childBirthDate;
        this.birthCertificateNumber = childCase.birthCertificateNumber;
        this.motherPhone = childCase.mothersPhone;
        this.address = childCase.childAddress;
        this.province = childCase.province;
        this.county = childCase.county;
        this.caregiverName = childCase.caregiverName;
        this.caregiverPhone = childCase.caregiverPhone;
    }

}
