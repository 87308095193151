import {WomanCaseModel} from './woman-case.model';

export class WomanDashboardModel {

    public type: string;
    public total: number;
    acuteMalnutrition: number;
    healed: number;
    outsideRiskZone: number;
    noMeasured: number;
    pregnant: number;
    lactating: number;
    hospitalized: number;
    interruptedTreatment: number;
    noProgress: number;
    transferred: number;

    constructor(type?: string) {
        if (type) {
            this.type = type;
        }
        this.total = 0;
        this.healed = 0;
        this.noMeasured = 0;
        this.outsideRiskZone = 0;
        this.acuteMalnutrition = 0;
        this.pregnant = 0;
        this.lactating = 0;
        this.hospitalized = 0;
        this.interruptedTreatment = 0;
        this.noProgress = 0;
        this.transferred = 0;
    }

    public init(type: string, womanCase: WomanCaseModel) {
        this.type = type;
        this.total++;
        const lastVisit = womanCase.visits != null ? this.getLastVisit(womanCase) : null;
        if ('woman.pregnant' == womanCase.type) {
            this.pregnant++;
        } else if ('woman.lactating' == womanCase.type) {
            this.lactating++;
        }

        switch (womanCase.marker) {
            case 'no-measured':
                this.noMeasured++;
                break;
            case 'healed':
                this.healed++;
                break;
            case 'outside-risk-zone':
                this.outsideRiskZone++;
                break;
            case 'acute-malnutrition':
                this.acuteMalnutrition++;
                break;
        }

        if (lastVisit) {
            if (lastVisit.fowardToHospital) {
                this.hospitalized++;
            }

            if (lastVisit.alertIcon != null && lastVisit.alertIcon.icon == 'exclamation-triangle') {
                this.noProgress++;
            }
        }

        if (womanCase.transferredCase || womanCase.transferAssistant != null) {
            this.transferred++;
        }

        if (womanCase.iconAlert != null && womanCase.iconAlert.icon == 'calendar-times') {
            this.interruptedTreatment++;
        }

    }

    public updateLevel(marker: string) {
        console.log('upgrade de ', marker);
        switch (marker) {
            case 'no-measured':
                this.noMeasured++;
                break;
            case 'healed':
                this.healed++;
                break;
            case 'outside-risk-zone':
                this.outsideRiskZone++;
                break;
            case 'acute-malnutrition':
                this.acuteMalnutrition++;
                break;
        }
    }

    public downgradeLevel(marker: string) {
        console.log('downgrade de ', marker);
        switch (marker) {
            case 'no-measured':
                this.noMeasured--;
                break;
            case 'healed':
                this.healed--;
                break;
            case 'outside-risk-zone':
                this.outsideRiskZone--;
                break;
            case 'acute-malnutrition':
                this.acuteMalnutrition--;
                break;
        }
    }

    private getLastVisit(womanCase: WomanCaseModel) {
        const visits = womanCase.visits.filter(visit => visit.date != null);
        if (visits == null || visits.length === 0) {
            return null;
        }
        return visits[visits.length - 1];
    }

}
