import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ProvinceService} from '../../services/province.service';
import {CountyService} from '../../services/county.service';
import {ProvinceModel} from '../../model/province.model';
import {CountyModel} from '../../model/county.model';
import {UnapModel} from '../../model/unap.model';
import {OrganizationService} from '../../services/organization.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {OngModel} from '../../model/ong.model';
import {CruzRojaService} from '../../services/cruz-roja.service';
import {CruzRojaModel} from '../../model/cruz-roja.model';
import {InaipiModel} from '../../model/inaipi.model';
import {InaipiService} from '../../services/inaipi.service';
import {HospitalService} from '../../services/hospital.service';
import {HospitalModel} from '../../model/hospital.model';

@Component({
    selector: 'location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, OnChanges {

    @Input()
    public organizationInitials: string;
    @Input()
    public province: ProvinceModel;
    @Input()
    public county: CountyModel;
    @Input()
    public countyName: string;
    @Input()
    public organization: UnapModel;
    @Input()
    public inaipiOrganization: InaipiModel;
    @Input()
    public cruzRojaOrganization: CruzRojaModel;
    @Input()
    public hospitalOrganization: HospitalModel;
    @Input()
    public organizationName: string;
    @Input()
    public direction: string;
    @Input()
    public showOrganizations: boolean;
    @Input()
    public showCounties: boolean = true;
    @Input()
    public loadOng: boolean = false;
    @Input()
    public loadCruzRojas: boolean = false;
    @Input()
    public loadInaipis: boolean = false;
    @Input()
    public loadHospitals: boolean = false;
    @Input()
    public organizationType: String = '';
    @Input()
    public disabled: boolean = false;

    @Output()
    outputProvince = new EventEmitter<ProvinceModel>();
    @Output()
    outputCounty = new EventEmitter<CountyModel>();
    @Output()
    outputOrganization = new EventEmitter<UnapModel>();
    @Output()
    outputInaipiOrganization = new EventEmitter<InaipiModel>();
    @Output()
    outputCruzRojaOrganization = new EventEmitter<CruzRojaModel>();
    @Output()
    outputHospitalOrganization = new EventEmitter<HospitalModel>();
    @Output()
    outputOrganizationName = new EventEmitter<String>();

    locationForm: FormGroup;

    private columnClass: string;

    private provinces = new Array<ProvinceModel>();
    private counties = new Array<CountyModel>();
    private organizations = new Array<UnapModel>();
    public ongs = new Array<OngModel>();
    public cruzRojas = Array<CruzRojaModel>();
    public inaipis = Array<InaipiModel>();
    public hospitals = Array<HospitalModel>();

    private organizationNameModel: string;

    private inited: boolean = false;

    constructor(private provinceService: ProvinceService,
                private formBuilder: FormBuilder,
                private organizationService: OrganizationService,
                private countyService: CountyService,
                private cruzRojaService: CruzRojaService,
                private inaipiService: InaipiService,
                private hospitalService: HospitalService) {
    }

    ngOnInit(): void {
        this.provinces = this.provinceService.listAll();
        this.counties = this.countyService.listByProvince(this.province);

        if (this.direction !== 'horizontal') {
            this.columnClass = 'col-6';
        } else {
            this.columnClass = 'col-12';
        }

        this.locationForm = this.formBuilder.group({
            provinceControl: [this.province?.id],
            countyControl: [this.county?.id],
            organizationControl: [this.organization?.id],
            organizationNameControl: [this.organizationName]
        });

        this.checkEnabledDisabled();

        if ((!this.county || !this.county.id) && this.countyName) {
            this.county = this.countyService.getByName(this.countyName);
        }

        if (this.county) {
            this.locationForm.get('provinceControl').setValue(this.county?.province);
            this.loadCounties();
        }

        this.organizationNameModel = this.organizationName;
    }

    checkEnabledDisabled() {
        if (this.disabled) {
            this.locationForm?.get('provinceControl')?.disable();
            this.locationForm?.get('countyControl')?.disable();
            this.locationForm?.get('organizationControl')?.disable();
            this.locationForm?.get('organizationNameControl')?.disable();
        } else {
            this.locationForm?.get('provinceControl')?.enable();
            this.locationForm?.get('countyControl')?.enable();
            this.locationForm?.get('organizationControl')?.enable();
            this.locationForm?.get('organizationNameControl')?.enable();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.checkEnabledDisabled();
        if (changes?.province && !changes?.province?.firstChange) {
            this.provinces = this.provinceService.listAll();
            this.counties = this.countyService.listByProvince(this.province);
        }
        if (changes?.organizationInitials?.previousValue !== changes?.organizationInitials?.currentValue) {
            this.locationForm.reset();
        }
    }

    loadCounties() {
        this.checkProvinceSelection();
        this.province = this.provinces.filter(province => province.id == this.locationForm.value.provinceControl)[0];
        if (this.loadOng) {
            this.loadOngs(this.province);
        }
        this.outputProvince.emit(this.province);
        this.counties = this.countyService.listByProvince(this.province);
        if (this.county?.id && !this.locationForm.value.countyControl) {
            this.locationForm.get('countyControl').setValue(this.county.id);
            this.setCounty();
        } else {
            this.setCounty();
        }
    }

    checkProvinceSelection() {
        if (!this.province) {
            return;
        }
        if (this.locationForm.value.provinceControl !== this.province) {
            this.locationForm.get('organizationControl').setValue(null);
            this.clearOrganization();
        }
    }

    setCounty() {
        if (!this.counties) {
            return;
        }
        this.county = this.counties.filter(county => county.id == this.locationForm.value.countyControl)[0];
        this.outputCounty.emit(this.county);
        this.loadOrganizations(this.county);
    }

    setOrganization(event) {
        if (!this.showOrganizations) {
            return;
        }
        const organizationId = event.target.value;
        if (organizationId === 'null') {
            this.clearOrganization();
            return;
        }
        if (this.loadOng) {
            const ong = this.ongs.filter(organization => organization.id === organizationId)[0];
            this.organization = new UnapModel(ong);
            this.outputOrganization.emit(this.organization);
        } else if (this.organizationType === 'Cruz Roja') {
            const organizationIdAsNumber = +organizationId;
            const cruzRoja = this.cruzRojas.find(organization => organization.id === organizationIdAsNumber);
            const cruzRojaModel = new CruzRojaModel(cruzRoja.id, cruzRoja.name, cruzRoja.countyId);
            this.outputCruzRojaOrganization.emit(cruzRojaModel);
        } else if (this.organizationType === 'INAIPI') {
            const organizationIdAsNumber = +organizationId;
            const inaipi = this.inaipis.find(organization => organization.id === organizationIdAsNumber);
            const inaipiOrganization = new InaipiModel(inaipi.id, inaipi.name, inaipi.countyId);
            this.outputInaipiOrganization.emit(inaipiOrganization);
        } else if (this.organizationType === 'Hospital') {
            const organizationIdAsNumber = +organizationId;
            const hospital = this.hospitals.find(organization => organization.id === organizationIdAsNumber);
            const hospitalOrganization = new HospitalModel(hospital.id, hospital.name, hospital.countyId);
            this.outputHospitalOrganization.emit(hospitalOrganization);
        } else {
            this.organization = this.organizations.find(organization => organization.id?.toString() === organizationId);
            this.outputOrganization.emit(this.organization);
        }
    }

    private clearOrganization() {
        this.organization = null;
        this.outputOrganization.emit(this.organization);
        this.outputCruzRojaOrganization.emit(null);
        this.outputInaipiOrganization.emit(null);
        this.outputHospitalOrganization.emit(null);
    }

    setOrganizationName(name) {
        this.organizationName = name;
        this.outputOrganizationName.emit(this.organizationNameModel);
    }

    loadOrganizations(county: CountyModel) {
        if (this.organizationType === 'Cruz Roja' && county && county.id) {
            this.loadCruzRojasByProvince(county);
        } else if (this.organizationType === 'INAIPI' && county && county.id) {
            this.loadInaipisByProvince(county);
        } else if (this.organizationType === 'Hospital' && county && county.id) {
            this.loadHospitalsByProvince(county);
        } else if (county && county.id) {
            const subscription = this.organizationService.listByCounty(county).subscribe(organizations => {
                subscription.unsubscribe();
                this.organizations = organizations as UnapModel[];
                if (this.organization && this.organization.id) {
                    if (!this.organizationService.permitFreeField(this.organization?.name)) {
                        this.locationForm.get('organizationControl').setValue(this.organization?.id);
                    }
                } else if (this.organizationName) {
                    this.organization = this.organizations.filter(organization => organization.name == this.organizationName)[0];
                    if (!this.organizationService.permitFreeField(this.organizationName)) {
                        this.locationForm.get('organizationControl').setValue(this.organization?.id);
                    }
                }
            });
        } else {
            this.organizations = null;
            this.outputOrganization.emit(null);
        }
    }

    loadOngs(province: ProvinceModel) {
        this.organizationService.listOng(province).subscribe(ongs => {
            this.ongs = ongs as OngModel[];
            const ong = this.ongs.filter(ong => ong.name == this.organizationName)[0];
            this.locationForm.get('organizationControl').setValue(ong?.id);
        });
    }

    loadCruzRojasByProvince(county: CountyModel) {
        this.cruzRojaService.listByCounty(county).subscribe(cruzRojas => {
            this.cruzRojas = cruzRojas as CruzRojaModel[];
            const selectecCruzRoja = this.cruzRojas.find(cruzRoja => cruzRoja.name === this.organizationName);
            this.locationForm.get('organizationControl').setValue(selectecCruzRoja?.id);
        });
    }

    loadInaipisByProvince(county: CountyModel) {
        this.inaipiService.listByCounty(county).subscribe(inaipis => {
            this.inaipis = inaipis as InaipiModel[];
            const selectecInaipi = this.inaipis.find(inaipi => inaipi.name === this.organizationName);
            this.locationForm.get('organizationControl').setValue(selectecInaipi?.id);
        });
    }

    // TODO renomear para loadHospitalsByCounty (os de cima tambem)
    loadHospitalsByProvince(county: CountyModel) {
        this.hospitalService.listByCounty(county).subscribe(hospitals => {
            this.hospitals = hospitals as HospitalModel[];
            const selectecHospital = this.hospitals.find(hospital => hospital.name === this.organizationName);
            this.locationForm.get('organizationControl').setValue(selectecHospital?.id);
        });
    }

    freeOrganizationInput() {
        const organization = this.organization
            || this.inaipiOrganization
            || this.cruzRojaOrganization
            || this.hospitalOrganization;
        const freeInput = (organization == null && this.organizationName != null && !this.loadOng)
            || (organization != null && this.organizationService.permitFreeField(organization?.name));
        return freeInput;
    }

}
