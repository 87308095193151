import firebase from 'firebase';
import {VisitModel} from './visit.model';
import {SymptomModel} from './symptom.model';
import {User} from './user.model';
import {CaseDelegationModel} from './case-delegation.model';
import {EatingHabitModel} from './eating-habit.model';
import GeoPoint = firebase.firestore.GeoPoint;
import {DateUtil} from '../util/date.util';
import {ProvinceModel} from './province.model';
import {CountyModel} from './county.model';
import {TokenGeneratorUtil} from '../util/token-generator.util';
import {WomanCaseType} from '../constants/woman-case-type';
import {TreatmentCase} from './treatment-case';
import {UnapModel} from './unap.model';

export class WomanCaseModel implements TreatmentCase {

    public objectID: string;
    public token: string;
    public muac: number;
    public firstName: string;
    public lastName: string;
    public birthDate: Date;
    public birthDateTimestamp: number;
    public visitDate: Date;
    public visitDateTimestamp: number;
    public identityCard: string;
    public phone: string;
    public chronicDisease: string;
    public type: string;
    public deliveryDate: Date;
    public lactationType: string;
    public gestationWeek: number;
    public province: string;
    public provinceModel: ProvinceModel;
    public county: string;
    public countyModel: CountyModel;
    public selfie: string;
    public selfieUrl: string;
    public covidSymptoms = new Array<SymptomModel>();
    public complicationsSymptoms = new Array<SymptomModel>();
    public obstetricDanger = new Array<SymptomModel>();
    public puerperiumDanger = new Array<SymptomModel>();
    public postpartumDanger = new Array<SymptomModel>();
    public healthAssistantsUids = new Array<string>();
    public visits = new Array<VisitModel>();
    public delegation: CaseDelegationModel;
    public healthAssistant: User;
    public identifierAssistant: User;
    public transferAssistant: User;
    public healthAssistants = new Array<User>();
    public currentAssistantUid: string;
    public address: string;
    public organizationInitials: string;
    public organizationName: string;
    public organization: UnapModel;
    public womanReferredToTheHospital: boolean;
    public provinceAssistance: string;
    public countyAssistance: string;
    public marker: string;
    public nextVisit: string;
    public nextVisitDate: Date;
    public nextVisitTimestamp: number;
    public updateDate: Date;
    public comments: string;
    public location: GeoPoint;
    public iconAlert: any;
    public transferredCase: boolean;
    public identifiedCase: boolean;
    public eatingHabits = new Array<EatingHabitModel>();
    public appVersion: string;
    public nationality: number;

    constructor(private assistant?: User) {
        if (assistant) {
            this.organizationName = assistant.organizationName;
            this.organizationInitials = assistant.organizationInitials;
            this.healthAssistant = assistant;
            this.token = TokenGeneratorUtil.generateToken();
            if (this.healthAssistants.filter(hAssistant => hAssistant.uid === assistant.uid).length === 0) {
                this.healthAssistants.push(assistant);
                this.healthAssistantsUids.push(assistant.uid);
                this.currentAssistantUid = assistant.uid;
            }
        }
    }

    public isLactating() {
        return this.type === WomanCaseType.LACTATING.i18nkey;
    }

    public isPregnant() {
        return this.type === WomanCaseType.PREGNANT.i18nkey;
    }

    public get age() {
        return DateUtil.yearsDiff(this.birthDate, new Date());
    }

    public get name() {
        return this.firstName + ' ' + this.lastName;
    }

    public update() {
        this.healthAssistants.push(this.healthAssistant);
        this.updateDate = new Date();
    }

    public addEatingHabits(eatingHabits: EatingHabitModel) {
        if (eatingHabits) {
            let order: number = this.visits.filter(visit => visit.date).length || 0;
            eatingHabits.order = order++;
            this.eatingHabits.push(eatingHabits);
        }
    }

    public updateByIdentification() {
        this.marker = 'no-measured';
        this.identifierAssistant = Object.assign({}, this.healthAssistant);
        this.organizationInitials = this.healthAssistant.organizationInitials;
        this.organizationName = this.delegation.unap;
        this.transferredCase = true;
        this.healthAssistants = [];
        this.healthAssistant = null;
        this.nextVisit = null;
        this.nextVisitDate = null;
        this.visits = [];
        this.healthAssistantsUids = new Array<string>();
        this.healthAssistantsUids.push(this.identifierAssistant.uid);
        this.identifiedCase = true;
    }

}
