import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(private router: Router) {
    }

    public toRoot() {
        this.router.navigate(['']).then(() => console.log('Redirecting to login...'));
    }

    public toMyCases() {
        this.router.navigate(['app/my-cases']).then(() => console.log('Redirecting to my cases...'));
    }

    public toDashboardVisionGeneral() {
        this.router.navigate(['app/dashboard/general-vision']).then(() => console.log('Redirecting to DashBoard Vision General...'));
    }

    public toChildrenList() {
        this.router.navigate(['app/children']).then(() => console.log('Redirecting to children...'));
    }

    public toNewChildCase() {
        this.router.navigate(['app/child-case']).then(() => console.log('Redirecting to new child case...'));
    }

    public toChildCase(token: string) {
        this.router.navigate(['app/child-profile/', token]).then(() => console.log('Redirecting to child case...'));
    }

    public toWomanCase(token: string) {
        this.router.navigate(['app/woman-profile/', token]).then(() => console.log('Redirecting to woman case...'));
    }

    public toNewWomanCase() {
        this.router.navigate(['app/woman-case']).then(() => console.log('Redirecting to new woman case...'));
    }

    public toUserEdit() {
        this.router.navigate(['app/user-info/edit']).then(() => console.log('Redirecting to user data edit...'));
    }

}
