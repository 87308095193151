import {Pipe, PipeTransform} from '@angular/core';
import {Filtering} from '../model/filtering';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

    transform(list: Array<Filtering>, value: string): any[] {
        if (!value || value.length === 0) {
            return list;
        }
        if (list) {
            value = value.toLowerCase();
            return list.filter(listing => listing.filterFirstOption().includes(value) ||
                listing.filterSecondOption().includes(value) ||
                listing.filterThirdOption().includes(value) ||
                listing.filterFourthOption().includes(value));
        }
    }

}
