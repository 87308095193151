export class MuacUtil {

    static getMuacClassification(muac: number) {

        if (muac <= 11.5) {
            return 'low-muac';
        }

        if (muac > 11.5 && muac <= 12.5) {
            return 'medium-muac';
        }

        if (muac > 12.5) {
            return 'high-muac';
        }

    }

    static getWomanMuacClassification(muac: number) {
        if (muac <= 24) {
            return 'woman-low-muac';
        }

        if (muac >= 24) {
            return 'woman-high-muac';
        }

    }

}
