import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {AngularFirestore, AngularFirestoreCollection, DocumentReference, QueryDocumentSnapshot} from '@angular/fire/firestore';
import {User} from '../model/user.model';
import {LoginService} from './login.service';
import {SrsService} from './srs.service';
import {ProvinceService} from './province.service';
import firebase from 'firebase';
import {map, take} from 'rxjs/operators';
import {AccountDetailsService} from './account-details.service';
import {WomanCaseModel} from '../model/woman-case.model';
import {WomanDashboardModel} from '../model/woman-dashboard.model';
import {SortUtil} from '../util/sort.util';
import {FilterUtil} from '../util/filter.util';
import {DpsService} from './dps.service';
import {ChildCaseModel} from '../model/child-case.model';
import {ChildrenDashboardModel} from '../model/children-dashboard.model';

export interface Item {
    id: string;
    ref: DocumentReference;
    data: WomanCaseModel;
}

@Injectable({
    providedIn: 'root'
})
export class WomenService {

    private itemsSubject: BehaviorSubject<Item[] | undefined> = new BehaviorSubject(undefined);
    private lastPageReached: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public nextQueryAfter: QueryDocumentSnapshot<WomanCaseModel>;
    private paginationSub: Subscription;
    private findSub: Subscription;
    public numberOfCases: number = 0;
    public reload = false;
    public loading = false;
    public user = JSON.parse(localStorage.getItem('user')) as User;

    constructor(private db: AngularFirestore,
                private loginService: LoginService,
                private srsService: SrsService,
                private dpsService: DpsService,
                private provinceService: ProvinceService) {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.reload = true;
                this.nextQueryAfter = undefined;
            }
        });
    }

    find(sortBy?: SortUtil, filterBy?: FilterUtil) {
        try {

            if (!sortBy) {
                sortBy = new SortUtil('nextVisitDate', 'asc');
            }

            if (sortBy && !filterBy) {
                filterBy = new FilterUtil(sortBy.field, '!=', null);
            }

            this.loading = true;
            const collection: AngularFirestoreCollection<WomanCaseModel> =
                this.getCollectionQuery(sortBy, filterBy);

            this.unsubscribe();

            this.paginationSub = collection.get()
                .subscribe(async (first) => {
                    this.nextQueryAfter = first.docs[first.docs.length - 1] as
                        QueryDocumentSnapshot<WomanCaseModel>;
                    await this.query(collection);
                });
        } catch (err) {
            throw err;
        }
    }

    private query(collection: AngularFirestoreCollection<WomanCaseModel>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            try {
                this.findSub = collection.snapshotChanges().pipe(
                    map(actions => {
                        return actions.map(a => {
                            const data: WomanCaseModel =
                                a.payload.doc.data() as WomanCaseModel;
                            const id = a.payload.doc.id;
                            const ref = a.payload.doc.ref;
                            return {
                                id,
                                ref,
                                data
                            };
                        });
                    })
                ).subscribe(async (items: Item[]) => {
                    await this.addItems(items);

                    resolve();
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    private addItems(items: Item[]): Promise<void> {
        return new Promise<void>((resolve) => {
            if ((!items || items.length <= 0) && !this.reload) {
                this.lastPageReached.next(true);
                resolve();
                return;
            }
            this.itemsSubject.asObservable().pipe(take(1))
                .subscribe((currentItems: Item[]) => {
                    if (currentItems && !this.reload) {
                        const newItems = items.filter(item => {
                            return currentItems.findIndex(i => i.id === item.id) === -1;
                        });

                        items.forEach(item => {
                            const index = currentItems.findIndex(currentItem => currentItem.id == item.id);
                            if (index != -1) {
                                currentItems[index] = item;
                            }
                        });

                        this.itemsSubject.next([...currentItems, ...newItems]);
                        this.reload = false;
                        this.loading = false;
                    } else {
                        this.itemsSubject.next([...items]);
                        this.reload = false;
                        this.loading = false;
                    }
                    resolve();
                });
        });
    }

    private getCollectionQuery(sortBy: SortUtil, filterBy?: FilterUtil): AngularFirestoreCollection<WomanCaseModel> {
        const profile = this.user?.organizationType;
        if (this.user.serviceType == 'IDENTIFY') {
            return this.getQueryForIdentifiers(sortBy, filterBy);
        }
        switch (this.prepareProfile(profile)) {
            case AccountDetailsService.UNICEF_SUPERVISOR:
                return this.getQueryForNationalSupervisor(sortBy, filterBy);
            case AccountDetailsService.SNS_SUPERVISOR:
                return this.getQueryForNationalSupervisor(sortBy, filterBy);
            case AccountDetailsService.DPS_SUPERVISOR:
                return this.getQueryForDPSSupervisor(sortBy, filterBy);
            case AccountDetailsService.SRS_SUPERVISOR:
                return this.getQueryForSRSSupervisor(sortBy, filterBy);
            default:
                return this.getQueryForUNAPSupervisor(sortBy, filterBy);
        }

    }

    private prepareProfile(profile: string) {
        if (!profile) {
            return '';
        }

        if (profile.toUpperCase().includes('SRS')) {
            return 'SRS';
        } else if (profile.toUpperCase().includes('SNS')) {
            return 'SNS';
        }

        return profile;
    }

    private getQueryForNationalSupervisor(sortBy: SortUtil, filterBy?: FilterUtil) {
        this.countNationalCases();
        if (this.nextQueryAfter) {
            return this.db.collection<WomanCaseModel>('womanCases', ref =>
                ref.where(filterBy.field, filterBy.option, filterBy.value).orderBy(sortBy.field, sortBy.direction)
                    .startAfter(this.nextQueryAfter)
                    .limit(100));
        } else {
            return this.db.collection<WomanCaseModel>('womanCases', ref =>
                ref.where(filterBy.field, filterBy.option, filterBy.value).orderBy(sortBy.field, sortBy.direction)
                    .limit(100));
        }
    }

    // By DPS: One province only
    private getQueryForDPSSupervisor(sortBy: SortUtil, filterBy?: FilterUtil) {
        this.provinceService.loadData();
        let dpsCode: number;
        if (this.user.dps) {
            dpsCode = this.user.dps.id;
        } else {
            dpsCode = this.dpsService.getByName(this.user.organizationName)?.id;
        }

        const provinces = this.provinceService.listByOrganization(dpsCode);

        if (this.nextQueryAfter) {
            return this.db.collection<WomanCaseModel>('womanCases', ref =>
                ref.where('province', 'in', provinces)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)
                    .startAfter(this.nextQueryAfter)
                    .limit(100));
        } else {
            this.countByOrganization(filterBy);
            return this.db.collection<WomanCaseModel>('womanCases', ref =>
                ref.where('province', 'in', provinces)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)
                    .limit(100));
        }
    }

    // By SRS: Multiple provinces
    private getQueryForSRSSupervisor(sortBy: SortUtil, filterBy?: FilterUtil) {
        this.provinceService.loadData();
        let srsCode: number;
        if (this.user.srs) {
            srsCode = this.user.srs.id;
        } else {
            srsCode = this.srsService.getByName(this.user.organizationName)?.id;
        }

        const provinces = this.provinceService.listByOrganization(srsCode);

        if (this.nextQueryAfter) {
            return this.db.collection<WomanCaseModel>('womanCases', ref =>
                ref.where('province', 'in', provinces)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)
                    .startAfter(this.nextQueryAfter)
                    .limit(100));
        } else {
            this.countByOrganization(filterBy);
            return this.db.collection<WomanCaseModel>('womanCases', ref =>
                ref.where('province', 'in', provinces)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)
                    .limit(100));
        }
    }

    // By UNAP
    private getQueryForUNAPSupervisor(sortBy: SortUtil, filterBy?: FilterUtil) {
        const organization = this.user.organizationName;

        if (this.nextQueryAfter) {

            return this.db.collection<WomanCaseModel>('womanCases', ref =>
                ref.where('organizationName', '==', organization)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)
                    .startAfter(this.nextQueryAfter)
                    .limit(100));
        } else {
            this.countByOrganization(filterBy);
            return this.db.collection<WomanCaseModel>('womanCases', ref =>
                ref.where('organizationName', '==', organization)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)
                    .limit(100));
        }
    }

    // For Identifiers
    private getQueryForIdentifiers(sortBy: SortUtil, filterBy?: FilterUtil) {

        if (this.nextQueryAfter) {
            return this.db.collection<WomanCaseModel>('/womanCases/', ref =>
                ref.where('identifierAssistant.organizationName', '==', this.user.organizationName)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)
                    .startAfter(this.nextQueryAfter)
                    .limit(100));
        } else {
            this.countIdentifiersCases();
            return this.db.collection<WomanCaseModel>('/womanCases/', ref =>
                ref.where('identifierAssistant.organizationName', '==', this.user.organizationName)
                    .where(filterBy.field, filterBy.option, filterBy.value)
                    .orderBy(sortBy.field, sortBy.direction)
                    .limit(100));
        }
    }

    private countNationalCases() {
        this.db.collection<ChildrenDashboardModel>('statisticWoman').doc('NATIONAL').get().subscribe(value => {
            this.numberOfCases = value.data().total;
        });
    }

    private countByOrganization(filterBy: FilterUtil) {
        this.db.collection<WomanCaseModel>('womanCases', ref => ref
            .where('organizationName', '==', this.user.organizationName)
            .where(filterBy.field, filterBy.option, filterBy.value))
            .get()
            .subscribe(values => this.numberOfCases = values.size);
    }

    private countIdentifiersCases() {
        this.db.collection<WomanDashboardModel>('womanCases', ref => ref.where('identifierAssistant.organizationName',
            '==', this.user.organizationName))
            .get().subscribe(result => this.numberOfCases = result.size);
    }

    destroy() {
        this.unsubscribe();
    }

    private unsubscribe() {
        if (this.paginationSub) {
            this.paginationSub.unsubscribe();
        }

        if (this.findSub) {
            this.findSub.unsubscribe();
        }
    }

    watchItems(): Observable<Item[]> {
        return this.itemsSubject.asObservable();
    }

    watchLastPageReached(): Observable<boolean> {
        return this.lastPageReached.asObservable();
    }

}
