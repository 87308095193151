import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WomenComponent} from './women.component';
import {FilterModule} from '../../components/filter/filter.module';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {VisitsModule} from '../../components/visits/visits.module';
import {EatingHabitsModule} from '../../components/eating-habits/eating-habits.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IconModule} from '../../components/icon/icon.module';
import {LoadingModule} from '../../components/loading/loading.module';
import {TranslocoModule} from '@ngneat/transloco';
import {MuacModule} from '../../components/muac/muac.module';
import {CaseAvatarModule} from '../../components/case-avatar/case-avatar.module';
import {OrganizationFilterModule} from '../../components/organization-filter/organization-filter.module';
import { PopoverModule, ButtonsModule, BsDropdownModule, ModalModule, PaginationModule  } from 'ngx-bootstrap';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

export const routes = [
    {path: '', component: WomenComponent}
];

@NgModule({
    declarations: [WomenComponent],
    imports: [
        CommonModule,
        FormsModule,
        ModalModule,
        RouterModule.forChild(routes),
        VisitsModule,
        EatingHabitsModule,
        FontAwesomeModule,
        IconModule,
        LoadingModule,
        FilterModule,
        OrganizationFilterModule,
        TranslocoModule,
        MuacModule,
        InfiniteScrollModule,
        CaseAvatarModule,
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        ButtonsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PaginationModule.forRoot()
    ], exports: [WomenComponent]
})
export class WomenModule {
    static routes = routes;
}
