import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase';
import { LoginModel } from '../model/login.model';
import { User } from '../model/user.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavigationService } from './navigation.service';
import { ProvinceModel } from '../model/province.model';
import { AccountDetailsService } from './account-details.service';
import {AngularFireAnalytics} from '@angular/fire/analytics';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    private persistenceType: string = firebase.auth.Auth.Persistence.LOCAL;
    public errorMessage: string;
    public currentUser: User;
    public provinces: ProvinceModel[];
    public province: ProvinceModel;

    constructor(public auth: AngularFireAuth,
        private db: AngularFirestore,
        private analytics: AngularFireAnalytics,
        private navigation: NavigationService) {
        this.observeUserStateChange();
    }

    public updateCurrentUser(user) {
        this.setCurrentUser(user);
        this.analytics.setUserId(this.currentUser.uid);
        this.analytics.setUserProperties(this.currentUser);
        console.log(this.analytics);
        this.srsInfo();
        this.dpsInfo();
        localStorage.setItem('user', JSON.stringify(this.currentUser));
        JSON.parse(localStorage.getItem('user'));
    }

    private observeUserStateChange() {
        this.auth.authState.subscribe(user => {
            if (user) {
                this.db.collection<User>('users').doc(user.uid).get().subscribe(foundUser => {
                    this.updateCurrentUser(foundUser.data());
                    this.navigate();
                });
            } else {
                localStorage.removeItem('user');
                this.currentUser = null;
                this.navigation.toRoot();
                JSON.parse(localStorage.getItem('user'));
            }
        });
    }

    private srsInfo() {
        if (this.currentUser.srs) {
            this.provinces = [];
            this.db.collection('provinces', ref => ref.where('srs', '==', this.currentUser.srs.id))
                .get().subscribe(provinces => {
                    provinces.forEach(province => this.provinces.push(province.data() as ProvinceModel));
                });
        }
    }

    private dpsInfo() {
        if (this.currentUser.dps) {
            this.db.collection('provinces', ref => ref.where('name', '==', this.currentUser.dps.provinceReference))
                .get().subscribe(provinces => {
                    provinces.forEach(province => this.province = province.data() as ProvinceModel);
                });
        }
    }

    private navigate() {
        if (sessionStorage.getItem('openChildProfileOnPopup')) {
            const childToken = sessionStorage.getItem('openChildProfileOnPopup');
            sessionStorage.removeItem('openChildProfileOnPopup');
            this.navigation.toChildCase(childToken);
            return;
        }
        if (sessionStorage.getItem('openWomanProfileOnPopup')) {
            const womanToken = sessionStorage.getItem('openWomanProfileOnPopup');
            sessionStorage.removeItem('openWomanProfileOnPopup');
            this.navigation.toWomanCase(womanToken);
            return;
        }
        if (this.currentUser.serviceType !== 'IDENTIFY_TREAT') {
            this.navigation.toDashboardVisionGeneral();
            return;
        }
        this.navigation.toMyCases();
    }

    public isCurrentUserSupervisor(user: User = null): boolean {
        const valid = user ?? this.getCurrentUser();
        return 'SUPERVISE' === valid.serviceType || valid.organizationInitials === 'Supervisores';
    }

    public isCurrentUserSupervisorOfInaipiOrCruzRojaOrHospital(user: User = null) {
        const validUser = user ?? this.getCurrentUser();
        const types = ['Cruz Roja', 'INAIPI', 'Hospital'];
        const isSupervisor = validUser.organizationInitials === 'Supervisores';
        const organizationTypeFinded = types.find(type => type === validUser?.organizationType) !== undefined;
        return isSupervisor && organizationTypeFinded;
    }

    public isCurrentUserIdentifyTreat(user: User = null): boolean {
        const valid = user ?? this.getCurrentUser();
        return 'IDENTIFY' === valid.serviceType || 'IDENTIFY_TREAT' === valid.serviceType;
    }

    public isCurrentUserUnicefOrSNS(user: User = null): boolean {
        const valid = user ?? this.getCurrentUser();
        const profileNormalized = this.normalizeProfile(valid.organizationType);
        if (profileNormalized === AccountDetailsService.UNICEF_SUPERVISOR
            || profileNormalized === AccountDetailsService.SNS_SUPERVISOR) {
            return true;
        }
        return false;
    }

    public isCurrentUserDpsSupervisor(user: User = null): boolean {
        const valid = user ?? this.getCurrentUser();
        const profileNormalized = this.normalizeProfile(valid.organizationType);
        if (profileNormalized === AccountDetailsService.DPS_SUPERVISOR) {
            return true;
        }
        return false;
    }

    public isCurrentUserSrsSupervisor(user: User = null): boolean {
        const valid = user ?? this.getCurrentUser();
        const profileNormalized = this.normalizeProfile(valid.organizationType);
        if (profileNormalized === AccountDetailsService.SRS_SUPERVISOR) {
            return true;
        }
        return false;
    }

    public isCurrentUserAdmin(user: User = null){
        const valid = user ?? this.getCurrentUser();
        return valid.isAdmin;
    }

    public normalizeProfile(profile: string) {
        if (!profile) {
            return '';
        }
        if (profile.toUpperCase().includes('SRS')) {
            return 'SRS';
        } else if (profile.toUpperCase().includes('SNS')
            || profile.toUpperCase().includes('MINISTERIO DE SALUD')) {
            return 'SNS';
        }
        return profile;
    }

    public get authUser(): User {
        return JSON.parse(localStorage.getItem('user'));
    }

    public get userDetails() {
        return this.auth.user;
    }

    public isAuthenticated() {
        return !!localStorage.getItem('user');
    }

    public updatePassword(password: string) {
        return this.auth.currentUser.then(user => {
            return user.updatePassword(password);
        });
    }

    public login(model: LoginModel) {
        return this.auth.setPersistence(this.persistenceType)
            .then(() => {
                this.errorMessage = null;
                return this.auth.signInWithEmailAndPassword(model.email, model.password);
            }).catch(error => {
                this.errorMessage = 'Usuario o contraseña no es válida';
                console.log(error);
            });
    }

    public resetPassword(emailAddress: string) {
        return this.auth.sendPasswordResetEmail(emailAddress);
    }

    public logout() {
        this.auth.signOut().then(() => {
            this.navigation.toRoot();
        });
    }

    private setCurrentUser(authUser: User) {
        this.currentUser = new User();
        this.currentUser = Object.assign(this.currentUser, authUser);
    }

    public getCurrentUser(): User {
        if (!this.currentUser) {
            this.setCurrentUser(this.authUser);
        }
        return this.currentUser;
    }

}
