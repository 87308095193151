import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OverallSimilarityComponent} from './overall-similarity.component';

@NgModule({
    declarations: [OverallSimilarityComponent],
    imports: [
        CommonModule
    ],
    exports: [
        OverallSimilarityComponent
    ],
})
export class OverallSimilarityModule {
}
