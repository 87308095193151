import {Injectable} from '@angular/core';
import algoliasearch from 'algoliasearch';
import {AlgoliaUtil} from '../util/algolia.util';
import {AngularFirestore} from '@angular/fire/firestore';
import {WomanCaseModel} from '../model/woman-case.model';
import {ChildCaseModel} from '../model/child-case.model';
import {DateUtil} from '../util/date.util';
import {User} from '../model/user.model';
import {ProvinceService} from './province.service';
import {DpsService} from './dps.service';
import {SrsService} from './srs.service';
import {LoginService} from './login.service';
import {AuthUser} from '../model/auth-user';
import * as moment from 'moment';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AlgoliaService {

    constructor(private db: AngularFirestore,
                private provinceService: ProvinceService,
                private dpsService: DpsService,
                private srsService: SrsService,
                private loginService: LoginService) {
    }

    getOrganizations() {
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getOrganizationAPIKey());
        const index = client.initIndex(AlgoliaUtil.getOrganizationIndex());

        index.search('UNAP', {
            hitsPerPage: 1000,
        }).then(({hits}) => {
            console.log(hits);
        });
    }

    test() {
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getChildrenAPIKey());
        const index = client.initIndex(AlgoliaUtil.getChildrenIndex());

        index.search('', {
            facetFilters: ['organizationName'],
            exactOnSingleWordQuery: 'attribute',
            synonyms: false,
        }).then(({hits}) => {
            console.log(hits);
        });

    }


    indexWomen() {
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getWomenAPIKey());
        const index = client.initIndex(AlgoliaUtil.getWomenIndex());

        this.db.firestore.collection('womanCases').get().then(values => {
            const women = values.docs.map(x => x.data() as WomanCaseModel);
            for (let i = 0; i < women.length; i++) {
                women[i].objectID = women[i].token;
                if (women[i].birthDate) {
                    women[i].birthDateTimestamp = DateUtil.toTimestamp(women[i].birthDate.toString());
                }

                if (women[i].visitDate) {
                    women[i].visitDateTimestamp = DateUtil.toTimestamp(women[i].visitDate.toString());
                }
            }

            index.saveObjects(women).then(({objectIDs}) => {
                console.log(objectIDs.length, 'saved');
            });
        });

    }

    indexChild() {
        // const currentDate = new Date();
        // const timestamp = currentDate.getTime();
        // console.log(timestamp);
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getChildrenAPIKey());
        const index = client.initIndex(AlgoliaUtil.getChildrenIndex());

        this.db.firestore.collection('cases').get().then(values => {
            const cases = values.docs.map(x => x.data() as ChildCaseModel);
            console.log('cases length', cases.length);
            let count = 0;
            for (let i = 0; i < cases.length; i++) {
                cases[i].objectID = cases[i].token;
                try {
                    if (cases[i].childBirthDate) {
                        cases[i].childBirthDateTimestamp = DateUtil.toTimestamp(cases[i].childBirthDate.toString());
                    }

                    if (cases[i].date) {
                        cases[i].dateTimestamp = DateUtil.toTimestamp(cases[i].date.toString());
                    }
                } catch (e) {
                    count++;
                    console.log(count + ' errors');
                }
            }

            index.saveObjects(cases).then(({objectIDs}) => {
                console.log(objectIDs.length, 'saved');
            });
        });

    }

    indexUsers() {
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getUsersAPIKey());
        const index = client.initIndex(AlgoliaUtil.getUsersIndex());

        this.db.firestore.collection('users').get().then(values => {
            const users = values.docs.map(x => x.data() as User);
            console.log('cases length', users.length);
            let count = 0;
            for (let i = 0; i < users.length; i++) {
                users[i].objectID = users[i].uid;
                const foundUser = AuthUser.users.filter(u => u.localId == users[i].uid);
                const date = new Date(parseInt(foundUser[0]?.createdAt));
                // tslint:disable-next-line:radix
                users[i].createdAt = parseInt(foundUser[0]?.createdAt);
                users[i].createdAtDay = moment(date).utc().startOf('day').valueOf();
                users[i].createdAtMonth = moment(date).utc().startOf('month').valueOf();
                users[i].createdAtYear = moment(date).utc().startOf('year').valueOf();
                count++;
            }

            index.saveObjects(users).then(({objectIDs}) => {
                console.log(objectIDs.length, 'saved');
            });
        });

    }

    public async getFilterByProfile(user: User, nextVisitDate?: any): Promise<any> {
        let filter = null;

        if (this.loginService.isCurrentUserUnicefOrSNS(user)) {
            return '';
        }

        if (this.loginService.isCurrentUserSupervisor(user)
            || this.loginService.isCurrentUserIdentifyTreat(user)) {
            filter = `organizationName:${user.organizationName}`;
        }

        if (this.loginService.isCurrentUserSupervisorOfInaipiOrCruzRojaOrHospital(user)) {
            filter = `organizationInitials:${user.organizationType}`;
        }

        if (this.loginService.isCurrentUserDpsSupervisor(user)) {
            filter = await this.getFilterDPSSuperviros(user);
        }

        if (this.loginService.isCurrentUserSrsSupervisor(user)) {
            filter = await this.getFilterSRSSupervisor(user);
        }

        return filter;
    }

    private async getFilterDPSSuperviros(user: User): Promise<string> {
        const dps = user.dps ? user.dps : await this.dpsService.searchByName(user.organizationName);
        const province = await this.provinceService.loadById(dps.province);
        return `province:${province.name}`;
    }

    private async getFilterSRSSupervisor(user: User): Promise<Array<string>> {
        const srs = user.srs ? user.srs : await this.srsService.searchByName(user.organizationName);
        const provinces = await this.provinceService.searchBySrs(srs.id);
        const provinceNames = provinces.map(x => x.name);
        return provinceNames.map(x => `province:${x}`);
    }

    async getResults(index, searchParam, searchConfig): Promise<any> {
        let pages = [];
        return index.search(searchParam, searchConfig).then(async data => {
            ``;
            pages.push(data);
            return pages;
        });
    }

    async getAllResults(index, searchParam, searchConfig, progressFunction: (n: number) => void = null): Promise<any> {
        let pages = [];
        return index.search(searchParam, searchConfig).then(async data => {
            pages.push(data);
            console.log(data);
            for (let i = 0; i < data.nbPages; i++) {
                let page = i + 1;
                searchConfig.page = page;
                if (progressFunction) {
                    progressFunction((page / data.nbPages) * 100);
                }
                const currentResult = await index.search(searchParam, searchConfig);
                pages.push(currentResult);
            }
            return pages;
        });
    }

    getAllResultsObservable(index, searchParam, searchConfig): Observable<Array<any>> {
        return new Observable(observer => {
            index.search(searchParam, searchConfig).then(async data => {
                observer.next(data.hits);
                for (let i = 0; i <= data.nbPages; i++) {
                    await new Promise(f => setTimeout(f, 300));
                    searchConfig.page = i + 1;
                    const currentResult = await index.search(searchParam, searchConfig);
                    observer.next(currentResult.hits);
                }
                observer.complete();
            });
        });
    }

    indexWithDate() {
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getChildrenAPIKey());
        const index = client.initIndex(AlgoliaUtil.getChildrenIndex());
        let count = 1;
        const tokens = [];
        // index.search('', {
        //     filters: 'NOT nextVisitTimestamp:0'
        // }).then(hits => {
        //     console.log(hits);
        // });
        for (let i = 0; i < 55; i++) {
            index.search('', {
                filters: 'NOT marker:healed AND NOT marker:no-measured',
                page: i,
                hitsPerPage: 10000
            }).then(result => {
                    // tokens.concat(...result.hits.map(hit => hit.objectID));
                    for (let j = 0; j < result.hits.length; j++) {
                        const object = result.hits[j] as ChildCaseModel;
                        if (object.nextVisit && !object.nextVisitDate) {
                            // console.log(object.token);
                            const parts = object.nextVisit.split('/');
                            const date = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
                            object.nextVisitTimestamp = date.getTime();
                            // if (object.nextVisitDate) {
                            //     object.nextVisitTimestamp = new Date(object.nextVisitDate).getTime();
                            // } else if (object.nextVisit) {
                            //     const parts = object.nextVisit.split('/');
                            //     const date = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
                            //     // console.log(`TOKEN: ${object.token}`, date);
                            //     object.nextVisitTimestamp = date.getTime();
                            //     console.log(object.nextVisitTimestamp);
                            //     // index.saveObject({
                            //     //     ...object,
                            //     //     objectID: object.token
                            //     // }).then(() => console.log('Atualizando', object.token))
                            //     //     .catch(() => console.error('Erro ao atualizar o registro nº', object.token));
                            // }
                            count++;
                        }

                    }
                }
            );
        }
    }

    indexWomanWithDate() {
        const client = algoliasearch(AlgoliaUtil.APP_ID, AlgoliaUtil.getWomenAPIKey());
        const index = client.initIndex(AlgoliaUtil.getWomenIndex());
        let count = 1;
        const tokens = [];
        // index.search('', {
        //     filters: 'NOT nextVisitTimestamp:0'
        // }).then(hits => {
        //     console.log(hits);
        // });
        for (let i = 0; i < 21; i++) {
            index.search('', {
                // filters: 'nextVisitTimestamp',
                page: i,
                hitsPerPage: 10000
            }).then(result => {
                    // tokens.concat(...result.hits.map(hit => hit.objectID));
                    for (let j = 0; j < result.hits.length; j++) {
                        const object = result.hits[j] as WomanCaseModel;
                        if (object.nextVisitDate && !object.nextVisitTimestamp) {
                            object.nextVisitTimestamp = new Date(object.nextVisitDate).getTime();
                            index.saveObject({
                                ...object,
                                objectID: object.token
                            }).then(() => console.log('Atualizando', object.token))
                                .catch(() => console.error('Erro ao atualizar o registro nº', object.token));
                            count++;
                        }

                    }
                }
            );
        }
    }

}
