export class TokenGeneratorUtil {

    static generateToken() {
        let text = '';
        for (let i = 0; i < 16; i++) {
            const a = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
            const b = a.toLowerCase();
            const possible = a.concat(b);
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

}
