import {Component, ElementRef, OnInit, ViewChild, Input, OnChanges} from '@angular/core';

@Component({
    selector: 'case-avatar',
    templateUrl: './case-avatar.component.html',
    styleUrls: ['./case-avatar.component.scss']
})
export class CaseAvatarComponent implements OnInit, OnChanges {
    @Input() source = '';
    @Input() class = '';
    @Input() customStyle = {};
    @Input() width = 45;
    @Input() height = 45;

    @ViewChild('avatar', {static: false}) avatar: ElementRef;

    imageOrientation = 'landscape';
    styleWrapper = {};

    constructor() {
    }

    onLoad() {
        const width = (this.avatar.nativeElement as HTMLImageElement).naturalWidth;
        const height = (this.avatar.nativeElement as HTMLImageElement).naturalHeight;

        if (height > width) {
            this.imageOrientation = 'portrait';
        }
    }

    ngOnChanges() {
        if (!this.source || (typeof this.source === 'string' && this.source.indexOf("no_profile_picture") >= 0)) {
            this.source = '/assets/img/icons/no_profile_picture.svg';
        }
    }

    ngOnInit(): void {
        this.styleWrapper = {
            height: `${this.height}px`,
            width: `${this.width}px`,
            ...this.customStyle,
        };
    }
}
