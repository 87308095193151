import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'overall-similarity',
    templateUrl: './overall-similarity.component.html',
    styleUrls: ['./overall-similarity.component.scss']
})
export class OverallSimilarityComponent implements OnInit {

    @Input()
    public value: number;

    constructor() {
    }

    ngOnInit() {
    }

}
