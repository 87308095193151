import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DashboardService} from '../../services/dashboard.service';
import {LoadingService} from '../../services/loading.service';
import {GeneralUpdateService} from '../../services/general-update.service';

@Component({
    selector: 'app-modal-dashboard-update',
    templateUrl: './modal-dashboard-update.component.html',
    styleUrls: ['./modal-dashboard-update.component.scss']
})
export class ModalDashboardUpdateComponent implements OnInit {

    option: string;
    resultMessage: string;
    showConfirmation = true;

    constructor(public activeModal: NgbActiveModal,
                public loading: LoadingService,
                public generalService: GeneralUpdateService,
                public dashboardService: DashboardService) {
    }

    confirm() {
        if ('woman' == this.option) {
            this.loading.start();
            this.dashboardService.updateWomanDashboard().then(result => {
                this.loading.stop();
                this.resultMessage = result?.count + ' registries of ' + result.cases + ' cases';
                this.showConfirmation = false;
            });
        }

        if ('child' == this.option) {
            this.loading.start();
            this.dashboardService.updateChildrenDashboard().then(result => {
                this.loading.stop();
                this.resultMessage = result?.count + ' registries of ' + result.cases + ' cases';
                this.showConfirmation = false;
            });
        }

    }


    downloadUsersCSV() {
        this.loading.start();
        this.generalService.listUsers().then(csv => {
            document.write(csv);
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            hiddenElement.target = '_blank';
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            hiddenElement.download = `users-list-${date}.csv`;
            hiddenElement.click();
            this.loading.stop();
        });
    }

    downloadWomanCSV() {
        this.loading.start();
        this.generalService.listWomen().then(csv => {
            document.write(csv);
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            // hiddenElement.target = '_blank';
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            hiddenElement.download = `women-list-${date}.csv`;
            hiddenElement.click();
            this.loading.stop();
        });
    }

    downloadChildrenCSV() {
        this.loading.start();
        this.generalService.listChildren().then(csv => {
            document.write(csv);
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            hiddenElement.target = '_blank';
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            hiddenElement.download = `children-list-${date}.csv`;
            hiddenElement.click();
            this.loading.stop();
        });
    }

    downloadGenreCSV() {
        this.loading.start();
        this.generalService.listChildrenGenre().then(csv => {
            document.write(csv);
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            hiddenElement.target = '_blank';
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            hiddenElement.download = `children-genre-list-${date}.csv`;
            hiddenElement.click();
            this.loading.stop();
        });
    }

    ngOnInit(): void {
    }


}
